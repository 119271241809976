import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ResetMessages, SetFormErrors } from '../../utils/misc/formControl';
import { Api } from '../../utils/services/Api';
import FormMessage from '../global/FormMessage';

function ProfilePassword() {

    const { t } = useTranslation();
    const [working, setWorking] = useState(false);
    const [data, setData] = useState({
        old_password: '',
        password: '',
        password_confirmation: ''
    });
    const [prevData, setPrevData] = useState(data);
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    useEffect(() => {
        ResetMessages(data, prevData, setPrevData, errors, setErrors, setErrorMessage);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
    );

    const handleSubmit = async (e) => {
        e.preventDefault();

        setWorking(true);
        ResetMessages(data, prevData, setPrevData, errors, setErrors, setErrorMessage, setSuccessMessage, true);

        try {
            const res = await Api.post('/profile/password', data);
            setSuccessMessage(res.data.message);
            setData({
                old_password: '',
                password: '',
                password_confirmation: ''
            });
        } catch (err) {
            SetFormErrors(err, errors, setErrors, setErrorMessage);
        }

        setWorking(false);
    }

    return (
        <div>
            <h3>{t('changePassword')}</h3>

            <Form onSubmit={handleSubmit} className="mt-4">
                <Form.Group controlId="old_password" className="mb-2">
                    <Form.Label>{t('oldPassword')}</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder={t('enterPassword')}
                        value={data.old_password}
                        onChange={(e) => setData({ ...data, old_password: e.target.value })}
                        isInvalid={!!errors.old_password}
                        {...working ? {disabled: true} : {}}
                    />
                    {errors.old_password && <Form.Control.Feedback type="invalid">{errors.old_password}</Form.Control.Feedback>}
                </Form.Group>

                <Form.Group controlId="password" className="mb-2">
                    <Form.Label>{t('newPassword')}</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder={t('enterPassword')}
                        value={data.password}
                        onChange={(e) => setData({ ...data, password: e.target.value })}
                        isInvalid={!!errors.password}
                        {...working ? {disabled: true} : {}}
                    />
                    {errors.password && <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>}
                </Form.Group>

                <Form.Group controlId="password_confirmation">
                    <Form.Label>{t('confirmPassword')}</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder={t('enterPassword')}
                        value={data.password_confirmation}
                        onChange={(e) => setData({ ...data, password_confirmation: e.target.value })}
                        isInvalid={!!errors.password_confirmation}
                        {...working ? {disabled: true} : {}}
                    />
                    {errors.password_confirmation && <Form.Control.Feedback type="invalid">{errors.password_confirmation}</Form.Control.Feedback>}
                </Form.Group>

                <Button type="submit" variant="primary" className="mt-3" {...working ? {disabled: true} : {} }>
                    {working && <FontAwesomeIcon icon={faSpinner} spin className="me-2" />}
                    {t('submit')}
                </Button>

                {errorMessage && <FormMessage type="danger" message={errorMessage} />}
                {successMessage && <FormMessage type="success" message={successMessage} />}
            </Form>
        </div>         
    );
}

export default ProfilePassword;