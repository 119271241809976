import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Row, Col, Container, Image, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../utils/hooks/AuthProvider';
import OffcanvasMenu from './OffcanvasMenu';
import Logo from '../../assets/images/logo.png';
import { mainMenuItems } from '../../utils/misc/mainMenuItems';

function Header() {

    const { t } = useTranslation();
    const { user, logout } = useAuth();
    const menuItems = mainMenuItems(user);
    const avatar = user.avatar ? <Image src={user.avatar} className="header-avatar" /> : <FontAwesomeIcon icon={faUserCircle} className="text-secondary header-avatar" />;

    const handleLogout = async () => {
        await logout();
    }

    return (
        <header className="bg-light top-0 w-100 d-flex align-items-center shadow-sm">
            <Container fluid="xxl">
                <Row>
                    <Col xs={5} xl={9} className="d-flex align-items-center">
                        {user.role !== 'user' &&
                            <OffcanvasMenu menuItems={menuItems} logo={Logo} />
                        }
                    
                        <Link to="/" className="logo">
                            <Image src={Logo} fluid />
                        </Link>

                        <ul className="p-0 m-0 list-inline list-unstyled ms-5 d-none d-xl-block">
                            {menuItems.map((item, index) => (
                                <li key={index} className="list-inline-item mx-3">
                                    <Link to={item.path} className="text-decoration-none link-dark nav-link">{item.name}</Link>
                                </li>
                            ))}
                        </ul>
                    </Col>

                    <Col xs={7} xl={3} className="d-flex align-items-center justify-content-end">
                        <Link to="/profile" className="text-decoration-none link-dark d-flex align-items-center">
                            {avatar}
                            <div style={{ lineHeight: '0.85rem' }} className="ms-2">
                                <small className="d-block" style={{ fontSize: '0.8rem' }}>{t('hello')}</small>
                                <small className="mb-0 fw-bold d-block">{user.first_name}</small>
                            </div>
                        </Link>

                        <span className="mx-3">|</span>

                        <Button className="icon-btn" onClick={handleLogout}>
                            <FontAwesomeIcon icon={faArrowRightFromBracket} className="text-warning" size="lg" title={t('logout')} />
                        </Button>
                    </Col>
                </Row>
            </Container>
        </header>
    );
}

export default Header;