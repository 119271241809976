import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useArtist } from '../../utils/hooks/ArtistProvider';
import { Form, Button, Modal, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ResetMessages, SetFormErrors } from '../../utils/misc/formControl';
import { Api } from '../../utils/services/Api';
import FormMessage from '../global/FormMessage';
import ArtistGalleryImage from './ArtistGalleryImage';
import BlockOverlay from '../global/BlockOverlay';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';

function ArtistGallery() {

    const { t } = useTranslation();
    const { loading, artist, getArtist } = useArtist();
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [workingUpload, setWorkingUpload] = useState(false);
    const [data, setData] = useState({
        gallery: []
    });
    const [prevData, setPrevData] = useState(data);
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState(null);
    const [galleryImages, setGalleryImages] = useState([]);


    useEffect(() => {
        ResetMessages(data, prevData, setPrevData, errors, setErrors, setErrorMessage);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lightboxOpen]);

    useEffect(() => {
        if (artist.gallery && artist.gallery.length > 0) {
            const images = [];
            artist.gallery.forEach((image) => images.push({ src: image.url }));
            setGalleryImages(images);
        }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [artist]);

    const handleUplaod = async (e) => {
        e.preventDefault();

        setWorkingUpload(true);
        ResetMessages(data, prevData, setPrevData, errors, setErrors, setErrorMessage, null, true);

        const gallery = Array.from(data.gallery);
        const formData = new FormData();
        gallery.forEach((image) => formData.append('gallery[]', image));

        try {
            await Api.post(`/artists/${artist.id}/gallery`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            setWorkingUpload(false);
            setModalShow(false);
            setData({ ...data, gallery: [] });
            getArtist();
        } catch (err) {
            SetFormErrors(err, errors, setErrors, setErrorMessage);
        } finally {
            setWorkingUpload(false);
        }
    }

    return (
        <>
            {loading ? <BlockOverlay /> :
                <div>
                    <h3>{t('gallery')} - {artist.name}</h3>

                    <Button variant="primary" onClick={() => setModalShow(true)}>
                        {t('upload')}
                    </Button>
        
                    <Modal show={modalShow}>
                        <div className="p-3">
                            <div className="d-flex justify-content-between align-items-center">
                                <h6 className="m-0">{t('uploadImages')}</h6>
                                <Button className="icon-btn" onClick={() => setModalShow(false)}>
                                    <FontAwesomeIcon icon={faTimes} size="lg" className="text-dark" />
                                </Button>
                            </div>
        
                            <div className="mt-4">
                                <Form onSubmit={handleUplaod}>
                                    <Form.Group className="mb-2" controlId="image">
                                        <Form.Label>{t('images')}</Form.Label>
                                        <Form.Control
                                            type="file"
                                            onChange={(e) => setData({ ...data, gallery: e.target.files })}
                                            isInvalid={!!errors.image}
                                            {...workingUpload ? { disabled: true } : {}}
                                            multiple
                                        />
                                    </Form.Group>
        
                                    <Button type="submit" variant="primary" className="mt-3" {...workingUpload ? {disabled: true} : {} }>
                                        {workingUpload && <FontAwesomeIcon icon={faSpinner} spin className="me-2" />}
                                        {t('upload')}
                                    </Button>
        
                                    {errorMessage && <FormMessage type="danger" message={errorMessage} />}
                                </Form>
                            </div>
                        </div>
                    </Modal>
        
                    <div className="mt-5">
                        <Row>
                            {artist.gallery.length === 0 ? '' :
                                artist.gallery.map((image, index) => (
                                    <Col key={index} xs={12} md={6} lg={4} xl={3} className="mb-3">
                                        <ArtistGalleryImage image={image} lightbox={setLightboxOpen} />
                                    </Col>
                                ))
                            }
                        </Row>

                        <Lightbox
                            open={lightboxOpen}
                            close={() => setLightboxOpen(false)}
                            slides={galleryImages}
                        />
                    </div>
                </div>
            }
        </>
    );
}

export default ArtistGallery;