export const selectStyles = {

    control: (provided, state) => ({
        ...provided,
        border: state.isFocused ? '1px solid #1d8975' : '1px solid #dee2e6',
        outline: 'none',
        boxShadow: 'none',
        '&:hover': {
            border: state.isFocused ? '1px solid #1d8975' : '1px solid #dee2e6',
        },
        borderRadius: '0.5rem',
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? '#e9f6f3' : 'transparent',
        color: '#212529',
        '&:hover': {
            backgroundColor: '#e9f6f3',
        },
    }),
};