import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEvent } from '../../utils/hooks/EventProvider';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Api } from '../../utils/services/Api';

function EventListSendCheckInvitations() {

    const { t } = useTranslation();
    const { event, getEvent } = useEvent();
    const [working, setWorking] = useState(false);

    const handleGenerate = async () => {

        setWorking(true);
        try {
            await Api.post(`/event-lists/${event.id}/send-check-invititations`);
            await getEvent();
        } catch (error) {
            // Silence is golden.
        } finally {
            setWorking(false);
        }
    }

    return (
        <Button
            variant="warning"
            onClick={handleGenerate}
            {...working && { disabled: true }}
        >
            {working && <FontAwesomeIcon icon={faSpinner} spin className="me-2" />}
            {t('send_check_invitations')}
        </Button>
    );
}

export default EventListSendCheckInvitations;