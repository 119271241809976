import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../utils/hooks/UserProvider';
import { Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Api } from '../../utils/services/Api';
import { ResetMessages, SetFormErrors } from '../../utils/misc/formControl';
import FormMessage from '../global/FormMessage';

function UserEditPasswordForm() {

    const { t } = useTranslation();
    const { user } = useUser();
    const [working, setWorking] = useState(false);
    const [data, setData] = useState({
        password: ''
    });
    const [prevData, setPrevData] = useState(data);
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    const randomPassword = (length) => {
        const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let password = '';

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            password += charset[randomIndex];
        }

        return password;
    }

    useEffect(() => {
        setData({ ...data, password: randomPassword(8) });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

    useEffect(() => {
        ResetMessages(data, prevData, setPrevData, errors, setErrors, setErrorMessage, setSuccessMessage);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
    );

    const handleSubmit = async (e) => {
        e.preventDefault();

        setWorking(true);
        ResetMessages(data, prevData, setPrevData, errors, setErrors, setErrorMessage, setSuccessMessage, true);

        try {
            const res = await Api.put(`/users/${user.id}/password`, data);
            setSuccessMessage(res.data.message);
        } catch (err) {
            SetFormErrors(err, errors, setErrors, setErrorMessage);
        } finally {
            setWorking(false);
        }
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group controlId="password">
                <Form.Label>{t('password')}</Form.Label>
                <Form.Control
                    type="text"
                    placeholder={t('password')}
                    value={data.password}
                    onChange={(e) => setData({ ...data, password: e.target.value })}
                    isInvalid={!!errors.password}
                    {...working && { disabled: true }}
                />
                {errors.password && <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>}
            </Form.Group>

            <Button type="submit" variant="primary" className="mt-3" {...working ? {disabled: true} : {} }>
                {working && <FontAwesomeIcon icon={faSpinner} spin className="me-2" />}
                {t('update')}
            </Button>

            {errorMessage && <FormMessage type="danger" message={errorMessage} />}
            {successMessage && <FormMessage type="success" message={successMessage} />}
        </Form>
    );
}

export default UserEditPasswordForm;