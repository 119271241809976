import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Modal, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Api } from '../../utils/services/Api';
import { SetFormErrors, ResetMessages } from '../../utils/misc/formControl';
import FormMessage from '../global/FormMessage';

function UserCreate() {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [modalShow, setModalShow] = useState(false);
    const [working, setWorking] = useState(false);
    const [data, setData] = useState({
        email: '',
        pid: '',
        first_name: '',
        last_name: '',
        send_email: false,
    });
    const [prevData, setPrevData] = useState(data);
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        ResetMessages(data, prevData, setPrevData, errors, setErrors, setErrorMessage);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        setWorking(true);
        ResetMessages(data, prevData, setPrevData, errors, setErrors, setErrorMessage, null, true);
        try {
            const res = await Api.post('/users', data);
            navigate(`/users/${res.data.data.id}`);
        } catch (err) {
            SetFormErrors(err, errors, setErrors, setErrorMessage);
        } finally {
            setWorking(false);
        }
    }

    return (
        <>
            <Button variant="primary" onClick={() => setModalShow(true)}>
                {t('newUser')}
            </Button>

            <Modal show={modalShow}>
                <div className="p-3">
                    <div className="d-flex justify-content-between align-items-center">
                        <h6 className="m-0">{t('createUser')}</h6>
                        <Button className="icon-btn" onClick={() => setModalShow(false)}>
                            <FontAwesomeIcon icon={faTimes} size="lg" className="text-dark" />
                        </Button>
                    </div>

                    <div className="mt-4">
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-2" controlId="email">
                                <Form.Label>{t('email')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={data.email || ''}
                                    onChange={(e) => setData({ ...data, email: e.target.value })}
                                    className={errors.email ? 'is-invalid' : ''}
                                    {...working ? { disabled: true } : {}}
                                />
                                {errors.email && <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>}
                            </Form.Group>

                            <Form.Group className="mb-2" controlId="pid">
                                <Form.Label>{t('personalNumber')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={data.pid || ''}
                                    onChange={(e) => setData({ ...data, pid: e.target.value })}
                                    className={errors.pid ? 'is-invalid' : ''}
                                    {...working ? { disabled: true } : {}}
                                />
                                {errors.pid && <Form.Control.Feedback type="invalid">{errors.pid}</Form.Control.Feedback>}
                            </Form.Group>

                            <Form.Group className="mb-2" controlId="first_name">
                                <Form.Label>{t('firstName')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={data.first_name || ''}
                                    onChange={(e) => setData({ ...data, first_name: e.target.value })}
                                    className={errors.first_name ? 'is-invalid' : ''}
                                    {...working ? { disabled: true } : {}}
                                />
                                {errors.first_name && <Form.Control.Feedback type="invalid">{errors.first_name}</Form.Control.Feedback>}
                            </Form.Group>

                            <Form.Group className="mb-2" controlId="last_name">
                                <Form.Label>{t('lastName')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={data.last_name || ''}
                                    onChange={(e) => setData({ ...data, last_name: e.target.value })}
                                    className={errors.last_name ? 'is-invalid' : ''}
                                    {...working ? { disabled: true } : {}}
                                />
                                {errors.last_name && <Form.Control.Feedback type="invalid">{errors.last_name}</Form.Control.Feedback>}
                            </Form.Group>

                            <Form.Group className="mb-2 mt-3" controlId="send_email">
                                <Form.Check
                                    type="checkbox"
                                    label={t('sendEmail')}
                                    checked={data.send_email}
                                    onChange={(e) => setData({ ...data, send_email: e.target.checked })}
                                    {...working ? { disabled: true } : {}}
                                />
                            </Form.Group>

                            <Button type="submit" variant="primary" className="mt-3" {...working ? {disabled: true} : {} }>
                                {working && <FontAwesomeIcon icon={faSpinner} spin className="me-2" />}
                                {t('submit')}
                            </Button>

                            {errorMessage && <FormMessage type="danger" message={errorMessage} />}
                        </Form>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default UserCreate;