import { Col, Container, Row } from 'react-bootstrap';
import AuthLanguageChange from '../../components/auth/LanguageForm';

function AuthLayout({ offLang, children }) {
    return (
        <Container fluid>
            <Row>
                <Col xs={12} md={7} lg={5} xxl={4} className="mx-auto mt-5">
                    <div className="block shadow position-relative">
                        {children}

                        <AuthLanguageChange offLang={offLang} />
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default AuthLayout;