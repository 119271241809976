import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { userMenuItems } from '../../utils/misc/userMenuItems';
import { UserProvider } from '../../utils/hooks/UserProvider';
import SingleLayout from '../../components/global/SingleLayout';
import UserBlacklists from '../../components/user/UserBlacklists';

function UserBlacklistsPage() {

    const { id } = useParams();
    const { t } = useTranslation();
    const menu = userMenuItems('/users/blacklists', id);

    return (
        <SingleLayout title={t('user')} menuItems={menu} back="/users">
            <div className="block shadow position-relative">
                <UserProvider>
                    <UserBlacklists />
                </UserProvider>
            </div>
        </SingleLayout>
    );
}

export default UserBlacklistsPage;