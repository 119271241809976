import i18n from '../../i18n';

export const partnerMenuItems = (active, id) => {

    let items = [
        {
            title: i18n.t('overview'),
            path: '/partners/' + id,
            active: false
        },
        {
            title: i18n.t('partnerInfo'),
            path: '/partners/info/' + id,
            active: false
        },
        {
            title: i18n.t('events'),
            path: '/partners/events/' + id,
            active: false
        },
        {
            title: i18n.t('delete'),
            path: '/partners/delete/' + id,
            active: false
        }
    ];

    items.forEach((item) => {
        if (active + '/' + id === item.path) {
            item.active = true;
        }
    });

    return items;
}