import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { partnerMenuItems } from '../../utils/misc/partnerMenuItems';
import { PartnerProvider } from '../../utils/hooks/PartnerProvider';
import SingleLayout from '../../components/global/SingleLayout';
import PartnerEditInfoForm from '../../components/partner/PartnerEditInfoForm';
import PartnerEditImageForm from '../../components/partner/PartnerEditImageForm';

function PartnerInfoPage() {

    const { id } = useParams();
    const { t } = useTranslation();
    const menu = partnerMenuItems('/partners/info', id);

    return (
        <SingleLayout title={t('partner')} menuItems={menu} back="/partners">
            <div className="block shadow position-relative">
                <PartnerProvider>
                    <PartnerEditImageForm />
                    <PartnerEditInfoForm />
                </PartnerProvider>
            </div>
        </SingleLayout>
    );
}

export default PartnerInfoPage;