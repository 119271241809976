import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { artistMenuItems } from '../../utils/misc/artistMenuItems';
import { ArtistProvider } from '../../utils/hooks/ArtistProvider';
import ArtistUser from '../../components/artist/ArtistUser';
import SingleLayout from '../../components/global/SingleLayout';

function ArtistUserPage() {

    const { id } = useParams();
    const { t } = useTranslation();
    const menu = artistMenuItems('/artists/user', id);

    return (
        <SingleLayout title={t('user')} menuItems={menu} back="/artists">
            <div className="block shadow position-relative">
                <ArtistProvider>
                    <ArtistUser />
                </ArtistProvider>
            </div>
        </SingleLayout>
    );
}

export default ArtistUserPage;