import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from '../../utils/hooks/ParamsProvider';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { selectStyles } from '../../utils/misc/selectStyles';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

function UsersFilterForm({ initial, callback, modal }) {
    
    const { t } = useTranslation();
    const { params } = useParams();
    const [data, setData] = useState({
        email: initial.email ? initial.email : '',
        pid: initial.pid ? initial.pid : '',
        name: initial.name ? initial.name : '',
        role: initial.role ? initial.role : '',
        status: initial.status ? initial.status : '',
        list: initial.list ? initial.list : '',
        friend: initial.friend ? initial.friend : ''
    });
    const [roleOptions, setRoleOptions] = useState([]);
    const [statusOptions, setStatusOptions] = useState([]);
    const [listOptions, setListOptions] = useState([]);
    const [friendOptions] = useState([
        { value: '', label: t('tesFriend') },
        { value: '1', label: t('yes') },
        { value: '0', label: t('no') }
    ]);

    useEffect(() => {
        if (Object.keys(params).length > 0) {
            setRoleOptions([
                { value: '', label: t('role') },
                ...params.user_roles
            ]);

            setStatusOptions([
                { value: '', label: t('status') },
                ...params.user_statuses
            ]);

            setListOptions([
                { value: '', label: t('list') },
                ...params.lists
            ]);
        }
    },
    // eslint-disable-next-line
    [params]);

    const handleSubmit = (e) => {
        e.preventDefault();
        callback(data);
        if (modal) {
            modal(false);
        }
    }

    const resetFilter = () => {
        setData({
            email: '',
            pid: '',
            name: '',
            role: '',
            status: '',
            list: '',
            friend: ''
        });
        callback({});
        if (modal) {
            modal(false);
        }
    }

    return (
        <>
            {Object.keys(params).length > 0 &&
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col xs={12} md={4} lg={3} xxl={2}>
                            <Form.Group className="mb-3" controlId="email">
                                <Form.Control
                                    type="text"
                                    placeholder={t('emailAddress')}
                                    value={data.email}
                                    onChange={(e) => setData({ ...data, email: e.target.value })}
                                />
                            </Form.Group>
                        </Col>

                        <Col xs={12} md={4} lg={3} xxl={2}>
                            <Form.Group className="mb-3" controlId="pid">
                                <Form.Control
                                    type="text"
                                    placeholder={t('personalNumber')}
                                    value={data.pid}
                                    onChange={(e) => setData({ ...data, pid: e.target.value })}
                                />
                            </Form.Group>
                        </Col>

                        <Col xs={12} md={4} lg={3} xxl={3}>
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Control
                                    type="text"
                                    placeholder={t('name')}
                                    value={data.name}
                                    onChange={(e) => setData({ ...data, name: e.target.value })}
                                />
                            </Form.Group>
                        </Col>

                        <Col xs={12} md={4} lg={3} xxl={2}>
                            <Form.Group className="mb-3" controlId="role">
                                <Select
                                    value={roleOptions.find((option) => option.value === data.role)}
                                    options={roleOptions}
                                    styles={selectStyles}
                                    placeholder={t('role')}
                                    onChange={(option) => setData({ ...data, role: option.value })}
                                />
                            </Form.Group>
                        </Col>
                                        
                        <Col xs={12} md={4} lg={3} xxl={2}>
                            <Form.Group className="mb-3" controlId="status">
                                <Select
                                    value={statusOptions.find((option) => option.value === data.status)}
                                    options={statusOptions}
                                    styles={selectStyles}
                                    placeholder={t('role')}
                                    onChange={(option) => setData({ ...data, status: option.value })}
                                />
                            </Form.Group>
                        </Col>

                        <Col xs={12} md={4} lg={3} xxl={2}>
                            <Form.Group className="mb-3" controlId="list">
                                <Select
                                    value={listOptions.find((option) => option.value === data.list)}
                                    options={listOptions}
                                    styles={selectStyles}
                                    placeholder={t('role')}
                                    onChange={(option) => setData({ ...data, list: option.value })}
                                />
                            </Form.Group>
                        </Col>

                        <Col xs={12} md={4} lg={3} xxl={2}>
                            <Form.Group className="mb-3" controlId="friend">
                                <Select
                                    value={friendOptions.find((option) => option.value === data.friend)}
                                    options={friendOptions}
                                    styles={selectStyles}
                                    placeholder={t('tesFriend')}
                                    onChange={(option) => setData({ ...data, friend: option.value })}
                                />
                            </Form.Group>
                        </Col>

                        <Col xs={12} md={4} lg={3} xxl={3}>
                            <Button type="submit" variant="primary">
                                {t('filter')}
                            </Button>

                            <Button type="button" variant='link' className="ms-3 rounded" size="sm" onClick={resetFilter}>
                                <FontAwesomeIcon icon={faTimes} className="me-2" />
                                {t('reset')}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            }
        </>
    );
}

export default UsersFilterForm;