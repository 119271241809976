import { Link } from 'react-router-dom';
import { Row, Col, Image, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faSoundcloud } from '@fortawesome/free-brands-svg-icons';
import BlockOverlay from '../global/BlockOverlay';

function ArtistsList({ loading, artists }) {
    return (
        <>
            {loading ? 
                <BlockOverlay />
            :
                <div className="mt-3">
                    <Row>
                        {artists.map((artist, index) => (
                            <Col key={index} xs={12} md={6} lg={4} xl={3} className="mb-3">
                                <Link to={`/artists/${artist.id}`} className="d-inline-block w-100 h-100">
                                    <div className="border rounded-4 p-3 mb-2 h-100 d-flex flex-column justify-content-between list-item">
                                        <div className="d-flex align-items-center">
                                            {artist.image ? <Image src={artist.image} className="artist-image" /> : <FontAwesomeIcon icon={faUserCircle} className="text-secondary artist-image" />}
                                            <div>
                                                <Badge variant="secondary">{artist.type_name}</Badge>
                                                <h6 className="mb-0 mt-2">{artist.name}</h6>
                                                <div className="mt-2">
                                                    {artist.instagram_url &&
                                                        <FontAwesomeIcon icon={faInstagram} className="me-2 text-warning" size="lg" />
                                                    }

                                                    {artist.soundcloud_url &&
                                                        <FontAwesomeIcon icon={faSoundcloud} className="me-2 text-warning" size="lg" />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                        ))}
                    </Row>
                </div>
            }
        </>
    );
}

export default ArtistsList;