import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../utils/hooks/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import ListLayout from '../components/global/ListLayout';
import BlockOverlay from '../components/global/BlockOverlay';
import { Api } from '../utils/services/Api';
import FormMessage from '../components/global/FormMessage';

function ActivityPage() {

    const { t } = useTranslation();
    const { loading, user } = useAuth();
    const navigate = useNavigate();
    const [working, setWorking] = useState(false);
    const [disabled, setDisabled] = useState(false); 
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!loading) {
            if (user.role === 'user') {
                navigate('/');
            }
        }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]);

    const registerActivity = async () => {

        setWorking(true);
        try {
            await Api.post('/event-activities/register');
        } catch (err) {
            setError(t('somethingWentWrong'))
        } finally {
            setWorking(false);
            setDisabled(true);

            setTimeout(() => {
                setDisabled(false);
                setError(null);
            }, 3000);
        }
    }

    return (
        <ListLayout>
            <div className="d-flex align-items-center mb-4">
                <Button className="icon-btn" onClick={() => navigate(-1)}><FontAwesomeIcon icon={faArrowLeft} className="me-3 text-dark" size="lg" /></Button>
                <h1 className="mb-0 me-3">{t('activity')}</h1>
            </div>

            <div className="block shadow position-relative">
                <p>{t('activityButtonDescription')}</p>
                {loading ? <BlockOverlay /> :
                    <>
                        <Button
                            variant="primary"
                            size="lg"
                            onClick={registerActivity}
                            {...working ? { disabled: true } : {}}
                            {...disabled ? { disabled: true } : {}}
                        >
                            {working && <FontAwesomeIcon icon={faSpinner} spin className="me-2" />}
                            {t('registerActivity')}
                        </Button>

                        {error && <FormMessage type="error" message={error} />}
                    </>
                }
            </div>
        </ListLayout>  
    );
}

export default ActivityPage;