import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Offcanvas, Button, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';

function OffcanvasMenu({ menuItems, logo }) {

    const [show, setShow] = useState(false);

    return (
        <>
            <Button className="icon-btn me-3 d-block d-xl-none" onClick={() => setShow(true)}>
                <FontAwesomeIcon icon={faBars} className="text-dark" size="lg" />
            </Button>

            <Offcanvas show={show} onHide={() => setShow(false)} className="border-0">
                <Offcanvas.Header className="p-3 border-bottom">
                    <div className="logo">
                        <Image src={logo} fluid />
                    </div>

                    <Button className="icon-btn" onClick={() => setShow(false)}>
                        <FontAwesomeIcon icon={faXmark} className="text-dark" size="lg" />
                    </Button>
                </Offcanvas.Header>

                <Offcanvas.Body>
                    <ul className="p-0 m-0 list-unstyled">
                        {menuItems.map((item, index) => (
                            <li key={index} className="my-3">
                                <Link to={item.path} onClick={() => setShow(false)} className="text-decoration-none link-dark nav-link">{item.name}</Link>
                            </li>
                        ))}
                    </ul>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default OffcanvasMenu;