import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Modal, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { SetFormErrors, ResetMessages } from '../../utils/misc/formControl';
import { Api } from '../../utils/services/Api';
import { parseDateTime } from '../../utils/misc/formatDate';
import FormMessage from '../global/FormMessage';
import DatePicker from 'react-datepicker';

function EventCreate() {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [modalShow, setModalShow] = useState(false);
    const [working, setWorking] = useState(false);
    const [data, setData] = useState({
        name: '',
        start: '',
        end: '',
        price: '',
        discounted_price: '',
        description: '',
    });
    const [prevData, setPrevData] = useState(data);
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        ResetMessages(data, prevData, setPrevData, errors, setErrors, setErrorMessage);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        setWorking(true);
        ResetMessages(data, prevData, setPrevData, errors, setErrors, setErrorMessage, null, true);

        try {
            const res = await Api.post('/events', data);
            navigate(`/events/${res.data.data.id}`);
        } catch (err) {
            SetFormErrors(err, errors, setErrors, setErrorMessage);
        } finally {
            setWorking(false);
        }
    }

    return (
        <>
            <Button variant="primary" onClick={() => setModalShow(true)}>
                {t('newEvent')}
            </Button>

            <Modal show={modalShow}>
                <div className="p-3">
                    <div className="d-flex justify-content-between align-items-center">
                        <h6 className="m-0">{t('createEvent')}</h6>
                        <Button className="icon-btn" onClick={() => setModalShow(false)}>
                            <FontAwesomeIcon icon={faTimes} size="lg" className="text-dark" />
                        </Button>
                    </div>

                    <div className="mt-4">
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-2" controlId="name">
                                <Form.Label>{t('name')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t('name')}
                                    value={data.name || ''}
                                    onChange={(e) => setData({ ...data, name: e.target.value })}
                                    className={errors.name ? 'is-invalid' : ''}
                                    {...working ? { disabled: true } : {}}
                                />
                                {errors.name && <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>}
                            </Form.Group>

                            <Form.Group className="mb-2" controlId="start">
                                <Form.Label>{t('startDate')}</Form.Label>
                                <DatePicker
                                    selected={data.start ? new Date(data.start) : null}
                                    onChange={(date) => setData({ ...data, start: parseDateTime(date) })}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    showTimeSelect
                                    dropdownMode="select"
                                    placeholderText={t('startDate')}
                                    dateFormat="yyyy-MM-dd hh:mm:ss"
                                    className={errors.start ? 'form-control is-invalid' : 'form-control'}
                                    {...working ? { disabled: true } : {}}
                                />
                                {errors.start && <Form.Control.Feedback type="invalid" style={{display: 'block'}}>{errors.start}</Form.Control.Feedback>}
                            </Form.Group>

                            <Form.Group className="mb-2" controlId="end">
                                <Form.Label>{t('endDate')}</Form.Label>
                                <DatePicker
                                    selected={data.end ? new Date(data.end) : null}
                                    onChange={(date) => setData({ ...data, end: parseDateTime(date) })}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    showTimeSelect
                                    dropdownMode="select"
                                    placeholderText={t('endDate')}
                                    dateFormat="yyyy-MM-dd hh:mm:ss"
                                    className={errors.end ? 'form-control is-invalid' : 'form-control'}
                                    {...working ? { disabled: true } : {}}
                                />
                                {errors.end && <Form.Control.Feedback type="invalid" style={{display: 'block'}}>{errors.end}</Form.Control.Feedback>}
                            </Form.Group>

                            <Form.Group className="mb-2" controlId="price">
                                <Form.Label>{t('price')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t('price')}
                                    value={data.price || ''}
                                    onChange={(e) => setData({ ...data, price: e.target.value })}
                                    isInvalid={!!errors.price}
                                    {...working ? { disabled: true } : {}}
                                />
                                {errors.price && <Form.Control.Feedback type="invalid">{errors.price}</Form.Control.Feedback>}
                            </Form.Group>

                            <Form.Group className="mb-2" controlId="discounted_price">
                                <Form.Label>{t('discountedPrice')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t('discountedPrice')}
                                    value={data.discounted_price || ''}
                                    onChange={(e) => setData({ ...data, discounted_price: e.target.value })}
                                    isInvalid={!!errors.discounted_price}
                                    {...working ? { disabled: true } : {}}
                                />
                                {errors.discounted_price && <Form.Control.Feedback type="invalid">{errors.discounted_price}</Form.Control.Feedback>}
                            </Form.Group>

                            <Form.Group className="mb-2" controlId="description">
                                <Form.Label>{t('description')}</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    placeholder={t('description')}
                                    value={data.description || ''}
                                    onChange={(e) => setData({ ...data, description: e.target.value })}
                                    isInvalid={!!errors.description}
                                    {...working ? { disabled: true } : {}}
                                />
                                {errors.description && <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>}
                            </Form.Group>

                            <Button type="submit" variant="primary" className="mt-3" {...working ? {disabled: true} : {} }>
                                {working && <FontAwesomeIcon icon={faSpinner} spin className="me-2" />}
                                {t('submit')}
                            </Button>

                            {errorMessage && <FormMessage type="danger" message={errorMessage} />}
                        </Form>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default EventCreate;