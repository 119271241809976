import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

function ListsFilterForm({ callback }) {

    const { t } = useTranslation();
    const [data, setData] = useState({
        name: ''
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        callback(data);
    }

    const resetFilter = () => {
        setData({
            name: ''
        });
        callback({});
    }

    return (
        <Form onSubmit={handleSubmit} className="mb-3">
            <Row>
                <Col xs={8} md={4} lg={3} xxl={2}>
                    <Form.Group controlId="name">
                        <Form.Control
                            type="text"
                            placeholder={t('name')}
                            value={data.name}
                            onChange={(e) => setData({ ...data, name: e.target.value })}
                        />
                    </Form.Group>
                </Col>

                <Col xs={4} md={2} lg={3} className="d-flex align-items-center">
                    <Button type="submit" variant="primary">
                        {t('filter')}
                    </Button>

                    <Button type="button" variant='link' className="ms-3 rounded" size="sm" onClick={resetFilter}>
                        <FontAwesomeIcon icon={faTimes} className="me-2" />
                        {t('reset')}
                    </Button>
                </Col>
            </Row>
        </Form>
    );
}

export default ListsFilterForm