import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEventList } from '../../utils/hooks/EventListProvider';
import { Badge, Col, Image, Row, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIdCard, faPhone, faSpinner, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { Api } from '../../utils/services/Api';

function EventSingleInvitation({ item, user }) {

    const { t } = useTranslation();
    const { eventList, getEventList } = useEventList();
    const [working, setWorking] = useState(false);

    const handleDelete = async (id) => {
        setWorking(true);
        try {
            await Api.delete(`/event-lists/${eventList.id}/remove-user/${item.id}`);
            getEventList();
        } catch (err) {
            // Silence is golden.
        } finally {
            setWorking(false);
        }
    }

    return (
        <>
            {user.role === 'administrator' ?
                <div className="border rounded-4 p-3 mb-2 list-item">
                    <Row>
                        <Col xs={12} md={4} className="d-flex align-items-center py-1">
                            {item.avatar ? <Image src={item.avatar} className="header-avatar" /> : <FontAwesomeIcon icon={faUserCircle} className="text-secondary header-avatar" />}
                            <div className="ms-3">
                                <p className="fw-bold m-0">
                                    {item.first_name} {item.last_name}
                                </p>

                                <p className="text-muted m-0" style={ {fontSize: '0.75rem'} }>
                                    <a href={`mailto:${item.email}`} className="muted">{item.email}</a>
                                </p>
                            </div>
                        </Col>

                        <Col xs={12} md={3} className="d-flex align-items-center py-1">
                            <div>
                                <p className="fw-bold m-0">
                                    <FontAwesomeIcon icon={faPhone} className="me-2" />
                                    {item.phone_code && item.phone ? `+${item.phone_code} ${item.phone}` : ''}
                                </p>

                                <p className="m-0">
                                    <FontAwesomeIcon icon={faIdCard} className="me-2" />
                                    {item.pid}
                                </p>
                            </div>
                        </Col>

                        <Col xs={12} md={2} className="d-flex align-items-center py-1">
                            <div className="m-0">
                                <Badge bg="dark">{item.role_name}</Badge><br/>
                                <Badge bg="warning">{item.status_name}</Badge>
                            </div>
                        </Col>

                        <Col xs={12} md={3} className="d-flex align-items-center justify-content-between py-1">
                            <div>
                                <Link to={`/users/${item.id}`} className="btn btn-sm btn-secondary rounded">
                                    {t('view')}
                                </Link>

                                {eventList.event.upcoming &&
                                <Button
                                    variant="danger"
                                    className="btn-sm ms-2 rounded"
                                    onClick={() => handleDelete(item.id)}
                                    {...working ? {disabled: true} : {} }
                                >
                                    {working && <FontAwesomeIcon icon={faSpinner} spin className="me-2" />}
                                    {t('delete')}
                                </Button>
                                }
                            </div>

                            {item.used ?
                                <Badge bg="primary">{t('used')}</Badge>
                            :
                                <Badge bg="danger">{t('unused')}</Badge>
                            }
                        </Col>
                    </Row>
                </div>
            :
                <h5>
                    <Badge bg="secondary" className="me-2 p-2 rounded">{item}</Badge>
                </h5>
            }
        </>
    )
}

export default EventSingleInvitation