import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Api } from '../../utils/services/Api';
import SetPasswordForm from '../../components/auth/SetPasswordForm';
import BlockOverlay from '../../components/global/BlockOverlay';
import AuthLayout from '../../components/global/AuthLayout';
import Logo from '../../assets/images/logo.png';

function SetPasswordPage() {
    
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { resetToken } = useParams();
    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState(false);

    const validateResetToken = async () => {
        try {
            const res = await Api.get('/auth/reset-password/' + resetToken);
            if (res.status === 200) {
                setLoading(false);
            }
        } catch (err) {
            navigate('/login');
        }
    }

    const callback = () => {
        setSuccess(true);
    }

    useEffect(() => {
        validateResetToken();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);


    return (
        <AuthLayout>
            {loading ?
                <BlockOverlay />
            :
                <>
                    {success ?
                        <div className="text-center my-4">
                            <FontAwesomeIcon icon={faCheckCircle} className="text-success" size='3x' />
                            <p className="mt-3">{t('passwordUpdatedSuccess')}</p>
                            <Link to="/login" className="btn btn-primary mt-3">{t('login')}</Link>
                        </div>
                    :
                        <>
                            <div className="login-logo">
                                <Image src={Logo} fluid />
                            </div>

                            <div className="mt-4">
                                <h4 className="mt-4 mb-0 fw-bold">{t('resetPassword')}</h4>
                                <small className="muted">{t('setnewPassword')}</small>
                            </div>
                            
                            <SetPasswordForm token={resetToken} callback={callback} />

                            <div className="mt-3">
                                <small className="muted">{t('wantToLogin')} <Link to="/login" className="link-primary text-decoration-underline">{t('login')}</Link></small>
                            </div>
                        </>
                    }
                </>
            }
        </AuthLayout>
    );
}

export default SetPasswordPage;