import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Row, Col, Image, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faImage, faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import BlockOverlay from '../global/BlockOverlay';
import { formatDateTime } from '../../utils/misc/formatDate';

function EventsList({ loading, events }) {

    const { t } = useTranslation();

    return (
        <>
            {loading ? 
                <BlockOverlay />
            :
                <div className="mt-4">
                    {events.map((event, index) => (
                        <div key={index} className="border rounded-4 p-3 mb-2 list-item">
                            <Row>
                                <Col xs={12} md={3} className="d-flex align-items-center py-1">
                                    {event.poster ? <Image src={event.poster} className="header-avatar" /> : <FontAwesomeIcon icon={faImage} className="text-secondary header-avatar" />}
                                    <div className="ms-3">
                                        <p className="fw-bold m-0">
                                            {event.name}
                                        </p>
                                    </div>
                                </Col>

                                <Col xs={12} md={3} className="d-flex align-items-center py-1">
                                    <p className="m-0">
                                        <FontAwesomeIcon icon={faCalendar} className="me-2" />
                                        <small>{formatDateTime(event.start)}</small>
                                    </p>
                                </Col>

                                <Col xs={12} md={2} className="d-flex align-items-center py-1">
                                    <p className="m-0">
                                        <FontAwesomeIcon icon={faMoneyBill} className="me-2" />
                                        {event.price} ₾
                                    </p>
                                </Col>

                                <Col xs={12} md={2} className="d-flex align-items-center py-1">
                                    {event.live ?
                                        <Badge bg="danger" className="me-2">{t('live')}</Badge>
                                    :
                                    event.upcoming ?
                                        <Badge bg="primary" className="me-2">{t('upcoming')}</Badge>
                                    :
                                        <Badge bg="secondary" className="me-2">{t('passed')}</Badge>
                                    }
                                </Col>

                                <Col xs={12} md={2} className="d-flex align-items-center py-1">
                                    <Link to={`/events/${event.id}`} className="btn btn-sm btn-secondary rounded">
                                        {t('view')}
                                    </Link>
                                </Col>
                            </Row>
                        </div>
                    ))}
                </div> 
            }
        </>
    );
}

export default EventsList;