import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../utils/hooks/UserProvider';
import { useParams } from '../../utils/hooks/ParamsProvider';
import { Col, Form, Row, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { selectStyles } from '../../utils/misc/selectStyles';
import { parseDate } from '../../utils/misc/formatDate';
import { ResetMessages, SetFormErrors } from '../../utils/misc/formControl';
import { Api } from '../../utils/services/Api';
import Select from 'react-select';
import BlockOverlay from '../global/BlockOverlay';
import DatePicker from 'react-datepicker';
import FormMessage from '../global/FormMessage';

function UserEditInfoForm() {

    const { t } = useTranslation();
    const { params } = useParams();
    const { loading, user, getUser } = useUser();
    const [working, setWorking] = useState(false);
    const [data, setData] = useState({});
    const [prevData, setPrevData] = useState(data);
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    const genderOptions = params.genders || [];
    const countryOptions = params.countries || [];
    const phoneCodeOptions = params.phone_codes || [];

    useEffect(() => {
        setData({
            email: user.email,
            pid: user.pid,
            first_name: user.first_name,
            last_name: user.last_name,
            gender: user.gender,
            birth_date: user.birth_date,
            country: user.country,
            phone_code: user.phone_code,
            phone: user.phone,
            facebook_url: user.facebook_url,
            instagram_url: user.instagram_url,
            bio: user.bio,
            tes_friend: user.tes_friend
        });

        setPrevData(data);
    }
    // eslint-disable-next-line
    , [user]);

    useEffect(() => {
        ResetMessages(data, prevData, setPrevData, errors, setErrors, setErrorMessage);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
    );

    const handleSubmit = async (e) => {
        e.preventDefault();

        setWorking(true);
        ResetMessages(data, prevData, setPrevData, errors, setErrors, setErrorMessage, setSuccessMessage, true);

        try {
            const res = await Api.put(`/users/${user.id}`, data);
            setSuccessMessage(res.data.message);
            getUser();
        } catch (err) {
            SetFormErrors(err, errors, setErrors, setErrorMessage);
        } finally {
            setWorking(false);
        }
    }

    return (
        <>
            {loading || Object.keys(data).length === 0 ?
                <BlockOverlay />
            :
                <Form className="mt-5" onSubmit={handleSubmit}>
                    <Row>
                        <Col xs={12} md={6}>
                            <Form.Group className="mb-2" controlId="email">
                                <Form.Label>{t('emailAddress')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t('email')}
                                    value={data.email || ''}
                                    onChange={(e) => setData({ ...data, email: e.target.value })}
                                    isInvalid={!!errors.email}
                                    {...working ? { disabled: true } : {}}
                                />
                                {errors.email && <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>}
                            </Form.Group>
                        </Col>

                        <Col xs={12} md={6}>
                            <Form.Group className="mb-2" controlId="pid">
                                <Form.Label>{t('personalNumber')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t('personalNumber')}
                                    value={data.pid || ''}
                                    onChange={(e) => setData({ ...data, pid: e.target.value })}
                                    isInvalid={!!errors.pid}
                                    {...working ? { disabled: true } : {}}
                                />
                                {errors.pid && <Form.Control.Feedback type="invalid">{errors.pid}</Form.Control.Feedback>}
                            </Form.Group>
                        </Col>

                        <Col xs={12} md={6}>
                            <Form.Group className="mb-2" controlId="firstName">
                                <Form.Label>{t('firstName')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t('firstName')}
                                    value={data.first_name || ''}
                                    onChange={(e) => setData({ ...data, first_name: e.target.value })}
                                    isInvalid={!!errors.first_name}
                                    {...working ? { disabled: true } : {}}
                                />
                                {errors.first_name && <Form.Control.Feedback type="invalid">{errors.first_name}</Form.Control.Feedback>}
                            </Form.Group>
                        </Col>

                        <Col xs={12} md={6}>
                            <Form.Group className="mb-2" controlId="lastName">
                                <Form.Label>{t('lastName')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t('lastName')}
                                    value={data.last_name || ''}
                                    onChange={(e) => setData({ ...data, last_name: e.target.value })}
                                    isInvalid={!!errors.last_name}
                                    {...working ? { disabled: true } : {}}
                                />
                                {errors.last_name && <Form.Control.Feedback type="invalid">{errors.last_name}</Form.Control.Feedback>}
                            </Form.Group>
                        </Col>

                        <Col xs={12} md={6}>
                            <Form.Group className="mb-2" controlId="gender">
                                <Form.Label>{t('gender')}</Form.Label>
                                <Select
                                    value={genderOptions.find((option) => option.value === data.gender)}
                                    options={genderOptions}
                                    styles={selectStyles}
                                    placeholder={t('selectDots')}
                                    className={`react-select ${errors.gender ? 'is-invalid' : ''}`}
                                    onChange={(option) => setData({ ...data, gender: option.value })}
                                    isDisabled={working}
                                />
                                {errors.gender && <Form.Control.Feedback type="invalid" style={{display: 'block'}}>{errors.gender}</Form.Control.Feedback>}
                            </Form.Group>
                        </Col>

                        <Col xs={12} md={6}>
                            <Form.Group className="mb-3" controlId="birth_date">
                                <Form.Label>{t('birthDate')}</Form.Label>
                                <DatePicker
                                    selected={data.birth_date ? new Date(data.birth_date) : null}
                                    onChange={(date) => setData({ ...data, birth_date: parseDate(date) })}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    dateFormat="yyyy-MM-dd"
                                    className={errors.birth_date ? 'form-control is-invalid' : 'form-control'}
                                    {...working ? { disabled: true } : {}}
                                />
                                {errors.birth_date && <Form.Control.Feedback type="invalid" style={{display: 'block'}}>{errors.birth_date}</Form.Control.Feedback>}
                            </Form.Group>
                        </Col>

                        <Col xs={12} md={6}>
                            <Form.Group className="mb-2" controlId="country">
                                <Form.Label>{t('country')}</Form.Label>
                                <Select
                                    value={countryOptions.find((option) => option.value === data.country)}
                                    options={countryOptions}
                                    styles={selectStyles}
                                    placeholder={t('selectDots')}
                                    className={`react-select ${errors.country ? 'is-invalid' : ''}`}
                                    onChange={(option) => setData({ ...data, country: option.value })}
                                    isDisabled={working}
                                />
                                {errors.country && <Form.Control.Feedback type="invalid" style={{display: 'block'}}>{errors.country}</Form.Control.Feedback>}
                            </Form.Group>
                        </Col>

                        <Col xs={6} md={2}>
                            <Form.Group className="mb-2" controlId="phone_code">
                                <Form.Label>{t('phoneCode')}</Form.Label>
                                <Select
                                    value={phoneCodeOptions.find((option) => option.value === data.phone_code)}
                                    options={phoneCodeOptions}
                                    styles={selectStyles}
                                    placeholder={t('selectDots')}
                                    className={`react-select ${errors.country ? 'is-invalid' : ''}`}
                                    onChange={(option) => setData({ ...data, phone_code: option.value })}
                                    isDisabled={working}
                                />
                                {errors.phone_code && <Form.Control.Feedback type="invalid" style={{display: 'block'}}>{errors.phone_code}</Form.Control.Feedback>}
                            </Form.Group>
                        </Col>

                        <Col xs={6} md={4}>
                            <Form.Group className="mb-2" controlId="phone">
                                <Form.Label>{t('phoneNumber')}</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder={t('phoneNumber')}
                                    value={data.phone || ''}
                                    onChange={(e) => setData({ ...data, phone: e.target.value })}
                                    isInvalid={!!errors.phone}
                                    {...working ? { disabled: true } : {}}
                                />
                                {errors.phone && <Form.Control.Feedback type="invalid" style={{display: 'block'}}>{errors.phone}</Form.Control.Feedback>}
                            </Form.Group>
                        </Col>

                        <Col xs={12} md={6}>
                            <Form.Group className="mb-2" controlId="facebook_url">
                                <Form.Label>{t('facebookUrl')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t('facebookUrl')}
                                    value={data.facebook_url || ''}
                                    onChange={(e) => setData({ ...data, facebook_url: e.target.value })}
                                    isInvalid={!!errors.facebook_url}
                                    {...working ? { disabled: true } : {}}
                                />
                                {errors.facebook_url && <Form.Control.Feedback type="invalid" style={{display: 'block'}}>{errors.facebook_url}</Form.Control.Feedback>}
                            </Form.Group>
                        </Col>

                        <Col xs={12} md={6}>
                            <Form.Group className="mb-2" controlId="instagram_url">
                                <Form.Label>{t('instagramUrl')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t('instagramUrl')}
                                    value={data.instagram_url || ''}
                                    onChange={(e) => setData({ ...data, instagram_url: e.target.value })}
                                    isInvalid={!!errors.instagram_url}
                                    {...working ? { disabled: true } : {}}
                                />
                                {errors.instagram_url && <Form.Control.Feedback type="invalid" style={{display: 'block'}}>{errors.instagram_url}</Form.Control.Feedback>}
                            </Form.Group>
                        </Col>

                        <Col xs={12}>
                            <Form.Group className="mb-2" controlId="bio">
                                <Form.Label>{t('bio')}</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    placeholder={t('bio')}
                                    value={data.bio || ''}
                                    onChange={(e) => setData({ ...data, bio: e.target.value })}
                                    isInvalid={!!errors.bio}
                                    {...working ? { disabled: true } : {}}
                                />
                                {errors.bio && <Form.Control.Feedback type="invalid" style={{display: 'block'}}>{errors.bio}</Form.Control.Feedback>}
                            </Form.Group>
                        </Col>

                        <Col xs={12}>
                            <Form.Group className="mb-2" controlId="tes_friend">
                                <Form.Check
                                    type="checkbox"
                                    label={t('tesFriend')}
                                    checked={data.tes_friend || false}
                                    onChange={(e) => setData({ ...data, tes_friend: e.target.checked })}
                                    isInvalid={!!errors.tes_friend}
                                    {...working ? { disabled: true } : {}}
                                />
                                {errors.tes_friend && <Form.Control.Feedback type="invalid" style={{display: 'block'}}>{errors.tes_friend}</Form.Control.Feedback>}
                            </Form.Group>
                        </Col>

                        <Col xs={12}>
                            <Button type="submit" variant="primary" className="mt-3" {...working ? {disabled: true} : {} }>
                                {working && <FontAwesomeIcon icon={faSpinner} spin className="me-2" />}
                                {t('update')}
                            </Button>

                            {errorMessage && <FormMessage type="danger" message={errorMessage} />}
                            {successMessage && <FormMessage type="success" message={successMessage} />}
                        </Col>
                    </Row>
                </Form>
            }
        </>
    );
}

export default UserEditInfoForm;