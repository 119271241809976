import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../utils/hooks/UserProvider';
import { Form, Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faTimes, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ResetMessages, SetFormErrors } from '../../utils/misc/formControl';
import { Api } from '../../utils/services/Api';
import { parseDate } from '../../utils/misc/formatDate';
import FormMessage from '../global/FormMessage';
import BlockOverlay from '../global/BlockOverlay';
import DatePicker from 'react-datepicker';
import UserSingleBlacklist from './UserSingleBlacklist';

function UserBlacklists() {

    const { t } = useTranslation();
    const { loading, user, getUser } = useUser();

    const [modalShow, setModalShow] = useState(false);
    const [working, setWorking] = useState(false);
    const [data, setData] = useState({
        start: null,
        end: null,
        reason: ''
    });
    const [prevData, setPrevData] = useState(data);
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        ResetMessages(data, prevData, setPrevData, errors, setErrors, setErrorMessage);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
    );

    const handleSubmit = async (e) => {
        e.preventDefault();

        setWorking(true);
        ResetMessages(data, prevData, setPrevData, errors, setErrors, setErrorMessage, null, true);

        try {
            await Api.post(`/blacklists/${user.id}`, data);
            setData({
                start: null,
                end: null,
                reason: ''
            });
            setModalShow(false);
            getUser();
        } catch (err) {
            SetFormErrors(err, errors, setErrors, setErrorMessage);
        } finally {
            setWorking(false);
        }
    }

    return (
        <>
            {loading ? <BlockOverlay /> :
                <div>
                    <h3>{t('Blacklists')} - {user.first_name} {user.last_name}</h3>

                    <Button variant="primary" className="me-3" onClick={() => setModalShow(true)}>
                        {t('newBlacklist')}
                    </Button>

                    <Modal show={modalShow}>
                        <div className="p-3">
                            <div className="d-flex justify-content-between align-items-center">
                                <h6 className="m-0">{t('createBlacklist')}</h6>
                                <Button className="icon-btn" onClick={() => setModalShow(false)}>
                                    <FontAwesomeIcon icon={faTimes} size="lg" className="text-dark" />
                                </Button>
                            </div>

                            <div className="mt-4">
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group className="mb-2" controlId="start">
                                        <Form.Label>{t('startDate')}</Form.Label>
                                        <DatePicker
                                            selected={data.start ? new Date(data.start) : null}
                                            onChange={(date) => setData({ ...data, start: parseDate(date) })}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            dateFormat="yyyy-MM-dd"
                                            className={errors.start ? 'form-control is-invalid' : 'form-control'}
                                            {...working ? { disabled: true } : {}}
                                        />
                                        {errors.start && <Form.Control.Feedback type="invalid" style={{display: 'block'}}>{errors.start}</Form.Control.Feedback>}
                                    </Form.Group>

                                    <Form.Group className="mb-2" controlId="end">
                                        <Form.Label>{t('endDate')}</Form.Label>
                                        <DatePicker
                                            selected={data.end ? new Date(data.end) : null}
                                            onChange={(date) => setData({ ...data, end: parseDate(date) })}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            dateFormat="yyyy-MM-dd"
                                            className={errors.end ? 'form-control is-invalid' : 'form-control'}
                                            {...working ? { disabled: true } : {}}
                                        />
                                        {errors.end && <Form.Control.Feedback type="invalid" style={{display: 'block'}}>{errors.end}</Form.Control.Feedback>}
                                    </Form.Group>

                                    <Form.Group className="mb-2" controlId="reason">
                                        <Form.Label>{t('reason')}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={data.reason || ''}
                                            onChange={(e) => setData({ ...data, reason: e.target.value })}
                                            className={errors.reason ? 'is-invalid' : ''}
                                            {...working ? { disabled: true } : {}}
                                        />
                                        {errors.reason && <Form.Control.Feedback type="invalid">{errors.reason}</Form.Control.Feedback>}
                                    </Form.Group>

                                    <Button type="submit" variant="primary" className="mt-3" {...working ? {disabled: true} : {} }>
                                        {working && <FontAwesomeIcon icon={faSpinner} spin className="me-2" />}
                                        {t('submit')}
                                    </Button>

                                    {errorMessage && <FormMessage type="danger" message={errorMessage} />}
                                </Form>
                            </div>
                        </div>
                    </Modal>

                    <div className="mt-5">
                        {user.blacklists && user.blacklists.length > 0 ?
                            <>
                                {user.blacklists.map((item, index) => (
                                    <UserSingleBlacklist key={index} item={item} />
                                ))}
                            </>
                        :
                            <div className="rounded-4 px-3 py-4 mt-4 info-box">
                                <p className="m-0">
                                    <FontAwesomeIcon icon={faInfoCircle} className="text-primary me-3" size="lg" />
                                    {t('noBlacklistsFound')}
                                </p>
                            </div>
                        }
                    </div>
                </div>
            }
        </>
    );
}

export default UserBlacklists;