import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import { Api } from '../utils/services/Api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import AuthLayout from '../components/global/AuthLayout';
import BlockOverlay from '../components/global/BlockOverlay';

function QRPage() {

    const { t } = useTranslation();
    const { uuid } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const [avatar, setAvatar] = useState(<FontAwesomeIcon icon={faUserCircle} className="text-secondary qr-user-image" />);
    
    const getInfo = async () => {
        
        try {
            const res = await Api.get(`/qr/${uuid}`);
            setData(res.data.data);
        } catch (err) {
            navigate('/not-found');
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getInfo();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

    useEffect(() => {
        if (data.avatar) {
            setAvatar(<Image src={data.avatar} className="qr-user-image" />);
        }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]);

    return (
        <AuthLayout offLang={true}>
            {loading ? <BlockOverlay /> :
                <div className="text-center">
                    {data.blacklisted &&
                    <div className="rounded-4 px-3 py-4 mt-3 bg-danger text-light">
                        <h5 className="m-0 fw-bold">
                            {t('userBlacklisted')}
                        </h5>
                    </div>
                    }

                    <div className="mt-4">
                        {avatar}
                    </div>
                    <h4 className="mb-0 mt-4">{data.name}</h4>
                    <p className="text-muted mt-2">{data.pid}</p>

                    {!data.blacklisted &&
                        <div className="rounded-4 px-3 py-4 mt-4 info-box">
                            <h5 className="m-0 fw-bold">
                                {data.status}
                            </h5>
                        </div>
                    }
                </div>
            }
        </AuthLayout>
    );
}

export default QRPage;