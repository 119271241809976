import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEvent } from '../../utils/hooks/EventProvider';
import { Image, Badge, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import BlockOverlay from '../global/BlockOverlay';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import { formatDateTime } from '../../utils/misc/formatDate';

function EventOverview() {

    const { t } = useTranslation();
    const [posterLightboxOpen, setPosterLightboxOpen] = useState(false);
    const [listPosterLightboxOpen, setListPosterLightboxOpen] = useState(false);
    const { loading, event } = useEvent();
    const poster = event.poster ? <Image src={event.poster} className="poster" /> : <FontAwesomeIcon icon={faImage} className="text-secondary poster" />;
    const listPoster = event.list_poster ? <Image src={event.list_poster} className="poster" /> : <FontAwesomeIcon icon={faImage} className="text-secondary poster" />;

    return (
        <>
            {loading ?
                <BlockOverlay />
            :
                <div className="profile-overview">
                    <h3>{t('overview')} - {event.name}</h3>

                    <div className="border rounded-4 p-3 d-flex align-items-center mt-4">
                        {event.poster ?
                            <>
                                <span onClick={() => setPosterLightboxOpen(true)} className="d-inline-block" style={{cursor: "pointer"}}>
                                    {poster}
                                </span>

                                <Lightbox
                                    open={posterLightboxOpen}
                                    close={() => setPosterLightboxOpen(false)}
                                    slides={[
                                        { src: event.poster }
                                    ]}
                                    carousel={{
                                        finite: true
                                    }}
                                />
                            </>
                        :
                            poster
                        }

                        <div className="ms-3">
                            <p className="fw-bold m-0">
                                {event.name}
                            </p>

                            <p className="m-0">
                                {event.live ?
                                    <Badge bg="danger" className="me-2">{t('live')}</Badge>
                                :
                                event.upcoming ?
                                    <Badge bg="primary" className="me-2">{t('upcoming')}</Badge>
                                :
                                    <Badge bg="secondary" className="me-2">{t('passed')}</Badge>
                                }
                            </p>
                        </div>
                    </div>

                    <div className="border rounded-4 p-3 d-flex align-items-center mt-4">
                        {event.list_poster ?
                            <>
                                <span onClick={() => setListPosterLightboxOpen(true)} className="d-inline-block" style={{cursor: "pointer"}}>
                                    {listPoster}
                                </span>

                                <Lightbox
                                    open={listPosterLightboxOpen}
                                    close={() => setListPosterLightboxOpen(false)}
                                    slides={[
                                        { src: event.list_poster }
                                    ]}
                                    carousel={{
                                        finite: true
                                    }}
                                />
                            </>
                        :
                            poster
                        }
                    </div>

                    <div className="mt-4 px-3">
                        <Table>
                            <tbody>
                                <tr>
                                    <th>{t('startDate')}</th>
                                    <td className="text-muted">{formatDateTime(event.start)}</td>
                                </tr>

                                <tr>
                                    <th>{t('endDate')}</th>
                                    <td className="text-muted">{formatDateTime(event.end)}</td>
                                </tr>

                                <tr>
                                    <th>{t('price')}</th>
                                    <td className="text-muted">{event.price} ₾</td>
                                </tr>

                                <tr>
                                    <th>{t('discountedPrice')}</th>
                                    <td className="text-muted">{event.discounted_price} ₾</td>
                                </tr>
                                
                                <tr>
                                    <th>{t('description')}</th>
                                    <td className="text-muted">{event.description}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
            }
        </>
    );
}

export default EventOverview;