import { useTranslation } from 'react-i18next';
import { useEventList } from '../../utils/hooks/EventListProvider';
import { useAuth } from '../../utils/hooks/AuthProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import BlockOverlay from '../global/BlockOverlay';
import EventSingleInvitation from './EventSingleInvitation';

function EventListInvitations() {

    const { t } = useTranslation();
    const { user } = useAuth();
    const { loading, eventList } = useEventList();

    return (
        <>
            {loading ? <BlockOverlay /> :
                <>
                    <div className="my-4">
                        <h5 className="m-0">{t('invited')}</h5>
                    </div>

                    {eventList.invitations && eventList.invitations.length > 0 ?
                        eventList.invitations.map((invitation, index) => (
                            <EventSingleInvitation key={index} item={invitation} user={user} />
                        ))
                    :
                        <div className="rounded-4 px-3 py-4 mt-4 info-box">
                            <p className="m-0">
                                <FontAwesomeIcon icon={faInfoCircle} className="text-primary me-3" size="lg" />
                                {t('noInvitationsFound')}
                            </p>
                        </div>
                    }
                </>
            }
        </>
    );
}

export default EventListInvitations;