import i18n from '../../i18n';

export const mainMenuItems = (user) => {

    const role = user.role ? user.role : null;
    if (!role) {
        return [];
    }

    let items = [];
    switch (role) {
        case 'doorman':
            items = [
                {
                    name: i18n.t('activity'),
                    path: '/activity',
                }
            ];
            break;

        case 'moderator':
            items = [
                {
                    name: i18n.t('artists'),
                    path: '/artists',
                },
                {
                    name: i18n.t('events'),
                    path: '/events',
                },
                {
                    name: i18n.t('activity'),
                    path: '/activity',
                }
            ];
            break;

        case 'administrator':
            items = [
                {
                    name: i18n.t('dashboard'),
                    path: '/',
                },
                {
                    name: i18n.t('users'),
                    path: '/users',
                },
                {
                    name: i18n.t('lists'),
                    path: '/lists',
                },
                {
                    name: i18n.t('artists'),
                    path: '/artists',
                },
                {
                    name: i18n.t('events'),
                    path: '/events',
                },
                {
                    name: i18n.t('partners'),
                    path: '/partners',
                },
                {
                    name: i18n.t('activity'),
                    path: '/activity',
                }
            ];
            break;

        default:
            items = [];
            break;
    }

    return items;
}