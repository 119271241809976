import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Api } from '../../utils/services/Api';
import Pagination from '../../components/global/Pagination';
import ListLayout from '../../components/global/ListLayout';
import EventCreate from '../../components/event/EventCreate';
import EventsFilterForm from '../../components/event/EventsFilterForm';
import EventsList from '../../components/event/EventsList';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

function EventsListPage() {

    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState({});
    const [events, setEvents] = useState([]);

    const setFilterData = (data) => {
        setData(data);
    }

    const getEvents = async () => {
        setLoading(true);
        try {
            const res = await Api.get('/events', {
                params: {
                    page: page,
                    ...data
                }
            });
            setEvents(res.data.data.events);
            setPagination(res.data.data.pagination);
        } catch (err) {
            setEvents([]);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        setPage(1);
        getEvents();
    },
    // eslint-disable-next-line
    [data]);

    useEffect(() => {
        getEvents();
    }
    // eslint-disable-next-line
    , [page]);

    return (
        <ListLayout>
            <div className="d-flex align-items-center mb-4">
                <Link to="/"><FontAwesomeIcon icon={faArrowLeft} className="me-3" size="lg" /></Link>
                <h1 className="mb-0 me-3">{t('events')}</h1>
                <EventCreate callback={getEvents} />
            </div>

            <div className="block shadow position-relative">
                <EventsFilterForm callback={setFilterData} />
                <EventsList loading={loading} events={events} getEvents={getEvents} />
                <Pagination page={page} lastPage={pagination.total_pages} setPage={setPage} />
            </div>
        </ListLayout>
    );
}

export default EventsListPage;