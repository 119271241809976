import React from 'react'
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import { colors } from '../../utils/misc/chartHelpers';

function PieChartComp({ data }) {
    
    return (
        <ResponsiveContainer width="100%" height={300}>
            <PieChart width={300} height={300}>
                <Pie
                    dataKey="value"
                    data={data}
                    cx={150}
                    cy={150}
                    innerRadius={60}
                    outerRadius={80}
                    fill="#8884d8"
                    paddingAngle={5}
                    label
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                    ))}
                </Pie>
                <Legend align="left" verticalAlign="middle" layout="vertical" />
                <Tooltip />
            </PieChart>
        </ResponsiveContainer>
    );
}

export default PieChartComp;