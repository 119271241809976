import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../utils/hooks/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { formatDateTime } from '../utils/misc/formatDate';
import { Col, Row } from 'react-bootstrap';
import { Api } from '../utils/services/Api';
import ListLayout from '../components/global/ListLayout';
import LiveEvent from '../components/dashboard/LiveEvent';
import UpcomingEvents from '../components/dashboard/UpcomingEvents';

function DashboardPage() {

    const { t } = useTranslation();
    const { user } = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [working, setWorking] = useState(true);
    const [liveEvent, setLiveEvent] = useState([]);
    const [upcomingEvents, setUpcomingEvents] = useState([]);

    useEffect(() => {
        if (user.role !== 'administrator') {
            navigate('/profile');
        }

        setLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

    const getData = async () => {
        try {
            const resLiveEvent = await Api.get('/event-activities/live-event');
            setLiveEvent(resLiveEvent.data.data);
        } catch (err) {
            // Silence is golden.
        } finally {
            setWorking(false);
        }

        try {
            const resUpcomingEvent = await Api.get('/event-activities/upcoming-events');
            setUpcomingEvents(resUpcomingEvent.data.data);
        } catch (err) {
            // Silence is golden.
        } finally {
            setWorking(false);
        }
    }

    useEffect(() => {
        if (user.role !== 'administrator') {
            return;
        }
        getData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

    return (
        <>
            {!loading && 
                <ListLayout>
                    <div className="d-flex align-items-center mb-4">
                        <h1 className="mb-0 me-3">{t('dashboard')}</h1>
                    </div>

                    <div className="block shadow position-relative">
                        <h3 className="text-primary">Hello {user.first_name}</h3>
                        <h5 className="fw-normal">Now is {formatDateTime(new Date())}</h5>

                        <Row>
                            <Col xs={12}>
                                <div className="border rounded-4 p-3 mt-4">
                                    <h6>{t('liveEvent')}</h6>

                                    <LiveEvent loading={working} events={liveEvent} />
                                </div>

                                <div className="border rounded-4 p-3 mt-4">
                                    <h6>{t('upcomingEvents')}</h6>

                                    <UpcomingEvents loading={working} events={upcomingEvents} />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </ListLayout>
            }
        </>
    )
}

export default DashboardPage;