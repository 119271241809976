import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from '../../utils/hooks/ParamsProvider';
import { Col, Container, Form, Row, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Api } from '../../utils/services/Api';
import { selectStyles } from '../../utils/misc/selectStyles';
import Select from 'react-select';

function UsersBulkAction({ users, setCheckedUsers, callback }) {

    const { t } = useTranslation();
    const { params } = useParams();
    const [working, setWorking] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState({
        action: '',
        list: ''
    });

    const listOptions = params.lists || [];
    const actionOptions = [
        { value: '', label: t('selectDots') },
        { value: 'list', label: t('addToList') },
        { value: 'delete', label: t('delete') }
    ]

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!data.action || (data.action === 'list' && !data.list)) {
            return;
        }

        let endpoint = '/lists/' + data.list + '/users';
        let method = 'post';

        if (data.action === 'delete') {
            endpoint = '/users/bulk-delete';
            method = 'post';
        }

        setWorking(true);

        try {
            await Api[method](endpoint, { users: users });
            setSuccess(true);
            setData({
                action: '',
                list: ''
            });
            setTimeout(() => {
                setSuccess(false);
                setCheckedUsers([]);
            }, 3000);
        } catch (err) {
            setError(err.response.data.message);
            setTimeout(() => {
                setError(null);
            }, 3000);
        } finally {
            setWorking(false);
            callback();
        }
    }

    return (
        <Form onSubmit={handleSubmit} className={`d-flex ${users && users.length > 0 ? 'd-block' : 'd-none'}`}>
            <Container fluid>
                <div className="p-3 rounded" style={{backgroundColor: '#f6f6f6'}}>
                    {success ?
                        <p className="m-0 text-primary">
                            <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                            {t('bulkSuccess')}
                        </p>
                    :
                    error ?
                        <p className="m-0 text-danger">
                            <FontAwesomeIcon icon={faTimesCircle} className="me-2" />
                            {error}
                        </p>
                    :
                        <Row>
                            <Col xs={12}>
                                <h6>{t('bulkActions')}</h6>
                            </Col>

                            <Col xs={6} md={4} lg={3} xxl={2}>
                                <Form.Group controlId="action">
                                    <Select
                                        options={actionOptions}
                                        placeholder={t('selectDots')}
                                        styles={selectStyles}
                                        onChange={(option) => setData({ ...data, action: option.value })}
                                    />
                                </Form.Group>
                            </Col>

                            <Col xs={6} md={4} lg={3} xxl={2} className={data.action === 'list' ? 'd-block' : 'd-none'}>
                                <Form.Group controlId="list">
                                    <Select
                                        options={listOptions}
                                        placeholder={t('selectDots')}
                                        styles={selectStyles}
                                        onChange={(option) => setData({ ...data, list: option.value })}
                                    />
                                </Form.Group>
                            </Col>

                            <Col xs={12} md={4} lg={3} xxl={2}>
                                <Button type="submit" variant="primary" {...working ? {disabled: true} : {} }>
                                    {working && <FontAwesomeIcon icon={faSpinner} spin className="me-2" />}
                                    {t('submit')}
                                </Button>
                            </Col>
                        </Row>
                    }
                </div>
            </Container>
        </Form>
    );
}

export default UsersBulkAction