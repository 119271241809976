import { useEventList } from '../../utils/hooks/EventListProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faImage, faKey, faUsers } from '@fortawesome/free-solid-svg-icons';
import { Col, Image, Row } from 'react-bootstrap';
import { formatDateTime } from '../../utils/misc/formatDate';
import BlockOverlay from '../global/BlockOverlay';

function EventListDetails() {

    const { loading, eventList } = useEventList();

    return (
        <>
            {loading ? <BlockOverlay /> :
                <div className="border rounded-4 p-3 mb-2">
                    <Row>
                        <Col xs={12} md={4} className="d-flex align-items-center py-1">
                            {eventList.event.poster ? <Image src={eventList.event.poster} className="header-avatar" /> : <FontAwesomeIcon icon={faImage} className="text-secondary header-avatar" />}
                            <div className="ms-3">
                                <p className="fw-bold m-0">
                                    {eventList.event.name}
                                </p>

                                <p className="fw-bold m-0">
                                    <FontAwesomeIcon icon={faCalendar} className="me-2" />
                                    <small>{formatDateTime(eventList.event.start)}</small>
                                </p>
                            </div>
                        </Col>

                        <Col xs={12} md={4} className="d-flex align-items-center mb-2 mb-md-0">
                            <div>
                                <p className="fw-bold m-0">
                                    <FontAwesomeIcon icon={faUsers} className="me-2" />
                                    <small>{eventList.max_invitations} / {eventList.invitations_count}</small>
                                </p>

                                <p className="fw-bold m-0">
                                    <FontAwesomeIcon icon={faKey} className="me-2 text-warning" />
                                    <small>{eventList.type_name}</small>
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
            }
        </>
    );
}

export default EventListDetails;