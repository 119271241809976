import { Col, Container, Row } from 'react-bootstrap';

function ListLayout({ children }) {
    return (
        <Container fluid="xxl">
            <Row>
                <Col xs={12}>
                    {children}
                </Col>
            </Row>
        </Container>
    );
}

export default ListLayout;