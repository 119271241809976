import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';

function ErrorPage() {

    const { t } = useTranslation();
    return (
        <Container fluid>
            <div className="d-flex min-vh-100 justify-content-center align-items-center">
                <div className="text-center">
                    <h1 className="text-dark display-2 text-monospace">404</h1>
                    <p className="text-monospace">{t('pageDoesNotExist')}</p>
                    <Link to="/" className="btn mt-4 btn-primary">{t('goHome')}</Link>
                </div>
            </div>
        </Container>
    );
}

export default ErrorPage;