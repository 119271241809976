import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { UserProvider } from '../../utils/hooks/UserProvider';
import { userMenuItems } from '../../utils/misc/userMenuItems';
import SingleLayout from '../../components/global/SingleLayout';
import UserEditInfoForm from '../../components/user/UserEditInfoForm';
import UserEditPasswordForm from '../../components/user/UserEditPasswordForm';
import UserEditAvatarForm from '../../components/user/UserEditAvatarForm';

function UserInfoPage() {

    const { t } = useTranslation();
    const { id } = useParams();
    const menu = userMenuItems('/users/info', id);

    return (
        <SingleLayout title={t('user')} menuItems={menu} back="/users">
            <div className="block shadow position-relative">
                <UserProvider>
                    <UserEditAvatarForm />
                    <UserEditInfoForm />
                </UserProvider>
            </div>

            <div className="block shadow position-relative mt-3">
                <UserProvider>
                    <UserEditPasswordForm />
                </UserProvider>
            </div>
        </SingleLayout>
    );
}

export default UserInfoPage;