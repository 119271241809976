import { useTranslation } from 'react-i18next';
import { useAuth } from '../../utils/hooks/AuthProvider';
import { Image, Badge, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faStar, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { formatDate } from '../../utils/misc/formatDate';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';

function ProfileOverview() {

    const { t } = useTranslation();
    const { user } = useAuth();
    const avatar = user.avatar ? <Image src={user.avatar} className="avatar" /> : <FontAwesomeIcon icon={faUserCircle} className="text-secondary avatar" />;
    const phone = user.phone_code && user.phone ? `+${user.phone_code} ${user.phone}` : '';

    return (
        <div className="profile-overview">
            <h3>{t('profileOverview')}</h3>

            <div className="border rounded-4 p-3 d-flex align-items-center mt-4">
                {avatar}
                <div className="ms-3">
                    <p className="fw-bold m-0">
                        {user.first_name} {user.last_name}
                    </p>

                    <p className="text-muted m-0" style={ {fontSize: '0.75rem'} }>
                        <a href={`mailto:${user.email}`} className="muted">{user.email}</a>
                    </p>

                    <p className="m-0">
                        <Badge bg="dark">{user.role_name}</Badge>
                        <Badge bg="warning" className="ms-2">{user.status_name}</Badge>
                        {user.tes_friend &&
                            <Badge bg="primary" className="ms-2">
                                <FontAwesomeIcon icon={faStar} className="me-2" />
                                {t('tesFriend')}
                            </Badge>
                        }
                    </p>
                </div>
            </div>

            <div className="mt-4 px-3">
                <Table>
                    <tbody>
                        <tr>
                            <th>{t('birthDate')}</th>
                            <td className="text-muted">{user.birth_date ? formatDate(user.birth_date) : ''}</td>
                        </tr>

                        <tr>
                            <th>{t('gender')}</th>
                            <td className="text-muted">{user.gender_name}</td>
                        </tr>

                        <tr>
                            <th>{t('personalNumber')}</th>
                            <td className="text-muted">{user.pid}</td>
                        </tr>

                        <tr>
                            <th>{t('contactPhone')}</th>
                            <td className="text-muted"><a href={`tel:${phone}`} className="muted p-0">{phone}</a></td>
                        </tr>

                        <tr>
                            <th>{t('country')}</th>
                            <td className="text-muted">{user.country_name}</td>
                        </tr>

                        <tr>
                            <th>{t('bio')}</th>
                            <td className="text-muted">{user.bio}</td>
                        </tr>

                        <tr>
                            <th>{t('social')}</th>
                            <td>
                                <ul className="list-unstyled m-0 p-0 list-inline">
                                    {user.facebook_url &&
                                        <li className="list-inline-item">
                                            <a rel="noreferrer" target="_blank" href={user.facebook_url}><FontAwesomeIcon icon={faFacebook} size="lg" /></a>
                                        </li>
                                    }

                                    {user.instagram_url &&
                                        <li className="list-inline-item">
                                            <a rel="noreferrer" target="_blank" href={user.instagram_url}><FontAwesomeIcon icon={faInstagram} size="lg" /></a>
                                        </li>
                                    }
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div>

            <div className="rounded-4 px-3 py-4 d-flex align-items-center mt-4 info-box">
                <p className="m-0">
                    <FontAwesomeIcon icon={faInfoCircle} className="text-primary me-3" size="lg" />
                    {t('ifIncorrectContactAt')} <a className="link-primary" href="mailto:info@tes-club.com">info@tes-club.com</a>
                </p>
            </div>
        </div>
    );
}

export default ProfileOverview;