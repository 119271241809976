import { Link } from 'react-router-dom';
import { Badge } from 'react-bootstrap';

function MobileAsideContent({ menuItems }) {
    return (
        <div className="d-flex align-items-center mobile-aside-content mb-3">
            {menuItems.map((item, index) => (
                <Link key={index} to={item.path} className="d-block me-2">
                    <Badge bg={item.active ? 'primary' : 'secondary'}>{item.title}</Badge>
                </Link>
            ))}
        </div>
    )
}

export default MobileAsideContent;