import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../utils/hooks/AuthProvider';
import { EventProvider } from '../../utils/hooks/EventProvider';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import ListLayout from '../../components/global/ListLayout';
import EventActivity from '../../components/event/EventActivity';

function EventStatsPage() {

    const { t } = useTranslation();
    const { loading, user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (user.role !== 'administrator') {
            navigate('/');
        }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]);

    return (
        <>
            {!loading &&
                <ListLayout>
                    <EventProvider>
                        <div className="d-flex align-items-center mb-4">
                            <Button className="icon-btn" onClick={() => navigate(-1)}><FontAwesomeIcon icon={faArrowLeft} className="me-3 text-dark" size="lg" /></Button>
                            <h1 className="mb-0 me-3">{t('stats')}</h1>
                        </div>

                        <div className="block shadow position-relative">
                            <EventActivity />
                        </div>
                    </EventProvider>
                </ListLayout>
            }
        </>
    );
}

export default EventStatsPage