import { Routes, Route } from 'react-router-dom';
import ErrorPage from './pages/ErrorPage';
import LoginPage from './pages/auth/LoginPage';
import ProtectedRoute from './utils/hooks/ProtectedRoute';
import './App.scss';
import '../node_modules/react-datepicker/dist/react-datepicker.css';
import ResetPasswordPage from './pages/auth/ResetPasswordPage';
import SetPasswordPage from './pages/auth/SetPasswordPage';
import ProfileOverviewPage from './pages/profile/ProfileOverviewPage';
import ProfileQRPage from './pages/profile/ProfileQRPage';
import ProfileBlacklistsPage from './pages/profile/ProfileBlacklistsPage';
import ProfilePasswordPage from './pages/profile/ProfilePasswordPage';
import UsersListPage from './pages/user/UsersListPage';
import UserOverviewPage from './pages/user/UserOverviewPage';
import UserQRPage from './pages/user/UserQRPage';
import UserInfoPage from './pages/user/UserInfoPage';
import UserRoleStatusPage from './pages/user/UserRoleStatusPage';
import UserBlacklistsPage from './pages/user/UserBlacklistsPage';
import UserDeletePage from './pages/user/UserDeletePage';
import ListsListPage from './pages/list/ListsListPage';
import ArtistsListPage from './pages/artist/ArtistsListPage';
import ArtistOverviewPage from './pages/artist/ArtistOverviewPage';
import ArtistInfoPage from './pages/artist/ArtistInfoPage';
import ArtistGalleryPage from './pages/artist/ArtistGalleryPage';
import ArtistUserPage from './pages/artist/ArtistUserPage';
import ArtistDeletePage from './pages/artist/ArtistDeletePage';
import UserArtistPage from './pages/user/UserArtistPage';
import EventsListPage from './pages/event/EventsListPage';
import EventOverviewPage from './pages/event/EventOverviewPage';
import EventInfoPage from './pages/event/EventInfoPage';
import EventArtistsPage from './pages/event/EventArtistsPage';
import EventDeletePage from './pages/event/EventDeletePage';
import EventListsPage from './pages/event/EventListsPage';
import DashboardPage from './pages/DashboardPage';
import AuthRoute from './utils/hooks/AuthRoute';
import UserListsPage from './pages/user/UserListsPage';
import ProfileEventListsPage from './pages/profile/ProfileEventListsPage';
import ProfileInvitationsPage from './pages/profile/ProfileInvitationsPage';
import EventListPage from './pages/eventlist/EventListPage';
import QRPage from './pages/QRPage';
import EventStatsPage from './pages/event/EventStatsPage';
import ActivityPage from './pages/ActivityPage';
import PartnerListPage from './pages/partner/PartnerListPage';
import PartnerOverviewPage from './pages/partner/PartnerOverviewPage';
import PartnerInfoPage from './pages/partner/PartnerInfoPage';
import PartnerDeletePage from './pages/partner/PartnerDeletePage';
import PartnerEventsPage from './pages/partner/PartnerEventsPage';
import EventPartnerPage from './pages/event/EventPartnerPage';
import ListUsersPage from './pages/list/ListUsersPage';

function App() {
    return (
        <Routes>
            <Route element={<AuthRoute />}>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/reset-password" element={<ResetPasswordPage />} />
                <Route path="/set-password/:resetToken" element={<SetPasswordPage />} />
            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path="/" element={<DashboardPage />} />

                {/** Profile */}
                <Route path="/profile" element={<ProfileOverviewPage />} />
                <Route path="/profile/qr" element={<ProfileQRPage />} />
                <Route path="/profile/blacklists" element={<ProfileBlacklistsPage />} />
                <Route path="/profile/change-password" element={<ProfilePasswordPage />} />
                <Route path="/profile/lists" element={<ProfileEventListsPage />} />
                <Route path="/profile/invitations" element={<ProfileInvitationsPage />} />

                {/** Users */}
                <Route path="/users" element={<UsersListPage />} />
                <Route path="/users/:id" element={<UserOverviewPage />} />
                <Route path="/users/qr/:id" element={<UserQRPage />} />
                <Route path="/users/info/:id" element={<UserInfoPage />} />
                <Route path="/users/role-status/:id" element={<UserRoleStatusPage />} />
                <Route path="/users/artist/:id" element={<UserArtistPage />} />
                <Route path="/users/lists/:id" element={<UserListsPage />} />
                <Route path="/users/blacklists/:id" element={<UserBlacklistsPage />} />
                <Route path="/users/delete/:id" element={<UserDeletePage />} />

                {/** Lists */}
                <Route path="/lists" element={<ListsListPage />} />
                <Route path="/lists/:id" element={<ListUsersPage />} />

                {/** Artists */}
                <Route path="/artists" element={<ArtistsListPage />} />
                <Route path="/artists/:id" element={<ArtistOverviewPage />} />
                <Route path="/artists/info/:id" element={<ArtistInfoPage />} />
                <Route path="/artists/gallery/:id" element={<ArtistGalleryPage />} />
                <Route path="/artists/user/:id" element={<ArtistUserPage />} />
                <Route path="/artists/delete/:id" element={<ArtistDeletePage />} />

                {/** Events */}
                <Route path="/events" element={<EventsListPage />} />
                <Route path="/events/:id" element={<EventOverviewPage />} />
                <Route path="/events/info/:id" element={<EventInfoPage />} />
                <Route path="/events/artists/:id" element={<EventArtistsPage />} />
                <Route path="/events/delete/:id" element={<EventDeletePage />} />
                <Route path="/events/lists/:id" element={<EventListsPage />} />
                <Route path="/events/partner/:id" element={<EventPartnerPage />} />

                {/** Event list */}
                <Route path="/event-lists/:id" element={<EventListPage />} />

                {/** Partners */}
                <Route path="/partners" element={<PartnerListPage />} />
                <Route path="/partners/:id" element={<PartnerOverviewPage />} />
                <Route path="/partners/info/:id" element={<PartnerInfoPage />} />
                <Route path="/partners/events/:id" element={<PartnerEventsPage />} />
                <Route path="/partners/delete/:id" element={<PartnerDeletePage />} />

                {/** Stats */}
                <Route path="/stats/:id" element={<EventStatsPage />} />

                {/** Activity */}
                <Route path="/activity" element={<ActivityPage />} />
            </Route>

            <Route path="/qr/:uuid" element={<QRPage />} />
            <Route path="*" element={<ErrorPage />} />
        </Routes>
    );
}

export default App;
