import { useState } from 'react';
import { useUser } from '../../utils/hooks/UserProvider';
import { useTranslation } from 'react-i18next';
import { Badge, Button } from 'react-bootstrap';
import { Api } from '../../utils/services/Api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

function UserSingleList({ item }) {

    const { t } = useTranslation();
    const { user, getUser } = useUser();
    const [working, setWorking] = useState(false);

    const handleDelete = async () => {

        setWorking(true);
        try {
            await Api.post(`/lists/${item.id}/delusers`, { users: [user.id] });
            getUser();
        } catch (err) {
            // Silence is golden.
        } finally {
            setWorking(false);
        }
    }

    return (
        <div className="border rounded-4 p-3 mb-2 h-100 d-flex flex-column justify-content-between">
            <div>
                <Badge variant="secondary">{item.users_count} {t('users')}</Badge>
                <h6 className="mb-0 mt-2">{item.name}</h6>
                <p>{item.description}</p>
            </div>

            <Button
                size="sm"
                className="mt-2 rounded"
                variant="danger"
                {...working ? {disabled: true} : {} }
                onClick={handleDelete}
                style={{width: 'fit-content'}}
            >
                {working && <FontAwesomeIcon icon={faSpinner} spin className="me-2" />}
                {t('remove')}
            </Button>
        </div>
    );
}

export default UserSingleList;