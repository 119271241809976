import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Api } from '../../utils/services/Api';
import ListLayout from '../../components/global/ListLayout';
import ListCreate from '../../components/list/ListCreate';
import ListsFilterForm from '../../components/list/ListsFilterForm';
import ListsList from '../../components/list/ListsList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

function ListsListPage() {

    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const [lists, setLists] = useState([]);

    const setFilterData = (data) => {
        setData(data);
    }

    const getLists = async () => {
        setLoading(true);
        try {
            const res = await Api.get('/lists', {
                params: {
                    ...data
                }
            });
            setLists(res.data.data);
        } catch (err) {
            setLists([]);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getLists();
    },
    // eslint-disable-next-line
    [data]);

    return (
        <ListLayout>
            <div className="d-flex align-items-center mb-4">
                <Link to="/"><FontAwesomeIcon icon={faArrowLeft} className="me-3" size="lg" /></Link>
                <h1 className="mb-0 me-3">{t('lists')}</h1>
                <ListCreate callback={getLists} />
            </div>

            <div className="block shadow position-relative">
                <ListsFilterForm callback={setFilterData} />
                <ListsList loading={loading} lists={lists} getLists={getLists} />
            </div>
        </ListLayout>
    );
}

export default ListsListPage;