import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { userMenuItems } from '../../utils/misc/userMenuItems';
import { UserProvider } from '../../utils/hooks/UserProvider';
import SingleLayout from '../../components/global/SingleLayout';
import UserLists from '../../components/user/UserLists';

function UserListsPage() {

    const { id } = useParams();
    const { t } = useTranslation();
    const menu = userMenuItems('/users/lists', id);

    return (
        <SingleLayout title={t('user')} menuItems={menu} back="/users">
            <div className="block shadow position-relative">
                <UserProvider>
                    <UserLists />
                </UserProvider>
            </div>
        </SingleLayout>
    );
}

export default UserListsPage;