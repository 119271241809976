import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useArtist } from '../../utils/hooks/ArtistProvider';
import { Image, Badge, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faSoundcloud } from '@fortawesome/free-brands-svg-icons';
import BlockOverlay from '../global/BlockOverlay';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';

function ArtistOverview() {

    const { t } = useTranslation();
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const { loading, artist } = useArtist();
    const image = artist.image ? <Image src={artist.image} className="avatar" /> : <FontAwesomeIcon icon={faUserCircle} className="text-secondary avatar" />;

    return (
        <>
            {loading ?
                <BlockOverlay />
            :
                <div className="profile-overview">
                    <h3>{t('overview')} - {artist.name}</h3>

                    <div className="border rounded-4 p-3 d-flex align-items-center mt-4">
                        {artist.image ?
                            <>
                                <span onClick={() => setLightboxOpen(true)} className="d-inline-block" style={{cursor: "pointer"}}>
                                    {image}
                                </span>

                                <Lightbox
                                    open={lightboxOpen}
                                    close={() => setLightboxOpen(false)}
                                    slides={[
                                        { src: artist.image }
                                    ]}
                                    carousel={{
                                        finite: true
                                    }}
                                />
                            </>
                        :
                            image
                        }

                        <div className="ms-3">
                            <p className="fw-bold m-0">
                                {artist.name}
                            </p>

                            <p className="m-0">
                                <Badge bg="dark">{artist.type_name}</Badge>
                            </p>
                        </div>
                    </div>

                    <div className="mt-4 px-3">
                        <Table>
                            <tbody>
                                <tr>
                                    <th>{t('bio')}</th>
                                    <td className="text-muted">{artist.bio}</td>
                                </tr>

                                <tr>
                                    <th>{t('social')}</th>
                                    <td>
                                        <ul className="list-unstyled m-0 p-0 list-inline">
                                            {artist.instagram_url &&
                                                <li className="list-inline-item">
                                                    <a rel="noreferrer" target="_blank" href={artist.instagram_url}><FontAwesomeIcon icon={faInstagram} size="lg" /></a>
                                                </li>
                                            }

                                            {artist.soundcloud_url &&
                                                <li className="list-inline-item">
                                                    <a rel="noreferrer" target="_blank" href={artist.soundcloud_url}><FontAwesomeIcon icon={faSoundcloud} size="lg" /></a>
                                                </li>
                                            }
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
            }
        </>
    );
}

export default ArtistOverview;