import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { partnerMenuItems } from '../../utils/misc/partnerMenuItems';
import { PartnerProvider } from '../../utils/hooks/PartnerProvider';
import SingleLayout from '../../components/global/SingleLayout';
import PartnerOverview from '../../components/partner/PartnerOverview';

function PartnerOverviewPage() {

    const { id } = useParams();
    const { t } = useTranslation();
    const menu = partnerMenuItems('/partners', id);

    return (
        <SingleLayout title={t('partner')} menuItems={menu} back="/partners">
            <div className="block shadow position-relative">
                <PartnerProvider>
                    <PartnerOverview />
                </PartnerProvider>
            </div>
        </SingleLayout>
    );
}

export default PartnerOverviewPage;