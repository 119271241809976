import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import Langauge from './Langauge';

function Footer() {
    const { t } = useTranslation();
    return (
        <footer className="bg-primary py-3 mt-auto">
            <Container fluid="xxl">
                <Row>
                    <Col>
                        <small className="text-light text-center mb-0">© {new Date().getFullYear()} {t('allRightsReserved')}</small>
                    </Col>

                    <Col className="text-end">
                        <Langauge />
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

export default Footer;