import { useTranslation } from 'react-i18next';
import { useArtist } from '../../utils/hooks/ArtistProvider';
import { Link } from 'react-router-dom';
import { Col, Row, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faUserCircle, faPhone, faIdCard } from '@fortawesome/free-solid-svg-icons';
import BlockOverlay from '../global/BlockOverlay';

function ArtistUser() {

    const { t } = useTranslation();
    const { loading, artist } = useArtist();

    return (
        <>
            {loading ? <BlockOverlay /> :
                <div>
                    <h3 className="mb-5">{t('user')} - {artist.name}</h3>

                    {!artist.user ?
                        <div className="rounded-4 px-3 py-4 mt-4 info-box">
                            <p className="m-0">
                                <FontAwesomeIcon icon={faInfoCircle} className="text-primary me-3" size="lg" />
                                {t('noUserFound')}
                            </p>
                        </div>  
                    :
                    
                    <div className="border rounded-4 p-3 mb-2">
                        <Row>
                            <Col xs={12} md={4} className="d-flex align-items-center py-1">
                                {artist.user.avatar ? <Image src={artist.user.avatar} className="header-avatar" /> : <FontAwesomeIcon icon={faUserCircle} className="text-secondary header-avatar" />}
                                <div className="ms-3">
                                    <p className="fw-bold m-0">
                                        {artist.user.first_name} {artist.user.last_name}
                                    </p>

                                    <p className="text-muted m-0" style={ {fontSize: '0.75rem'} }>
                                        <a href={`mailto:${artist.user.email}`} className="muted">{artist.user.email}</a>
                                    </p>
                                </div>
                            </Col>

                            <Col xs={12} md={3} className="d-flex align-items-center py-1">
                                <div>
                                    <p className="fw-bold m-0">
                                        <FontAwesomeIcon icon={faPhone} className="me-2" />
                                        {artist.user.phone_code && artist.user.phone ? `+${artist.user.phone_code} ${artist.user.phone}` : ''}
                                    </p>

                                    <p className="m-0">
                                        <FontAwesomeIcon icon={faIdCard} className="me-2" />
                                        {artist.user.pid}
                                    </p>
                                </div>
                            </Col>

                            <Col xs={12} md={2} className="d-flex align-items-center py-1">
                                <Link to={`/users/${artist.user.id}`} className="btn btn-sm btn-secondary rounded">
                                    {t('view')}
                                </Link>
                            </Col>
                        </Row>
                    </div>
                    }
                </div>
            }
        </>
    );
}

export default ArtistUser;