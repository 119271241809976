import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'react-bootstrap';
import { faFilter, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import UsersFilterForm from './UsersFilterForm';

function UsersMobileFilter({ initial, callback }) {

    const { t } = useTranslation();
    const [show, setShow] = useState(false);

    return (
        <div className="mb-3">
            <Button variant="secondary" className="btn-sm rounded" onClick={() => setShow(true)}>
                <FontAwesomeIcon icon={faFilter} className="me-2" />
                {t('filter')}
            </Button>

            <Modal show={show}>
                <div className="p-3">
                    <div className="d-flex justify-content-between align-items-center">
                        <h6 className="m-0">{t('filter')}</h6>
                        <Button className="icon-btn" onClick={() => setShow(false)}>
                            <FontAwesomeIcon icon={faTimes} size="lg" className="text-dark" />
                        </Button>
                    </div>

                    <div className="mt-4">
                        <UsersFilterForm callback={callback} modal={setShow} initial={initial} />
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default UsersMobileFilter;