import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useArtist } from '../../utils/hooks/ArtistProvider';
import { useParams } from '../../utils/hooks/ParamsProvider';
import { Col, Form, Row, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { selectStyles } from '../../utils/misc/selectStyles';
import { ResetMessages, SetFormErrors } from '../../utils/misc/formControl';
import { Api } from '../../utils/services/Api';
import Select from 'react-select';
import BlockOverlay from '../global/BlockOverlay';
import FormMessage from '../global/FormMessage';

function ArtistEditInfoForms() {

    const { t } = useTranslation();
    const { params } = useParams();
    const { loading, artist, getArtist } = useArtist();
    const [working, setWorking] = useState(false);
    const [data, setData] = useState({});
    const [prevData, setPrevData] = useState(data);
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    const artistTypeOptions = params.artist_types || [];

    useEffect(() => {
        setData({
            type: artist.type,
            name: artist.name,
            instagram_url: artist.instagram_url,
            soundcloud_url: artist.soundcloud_url,
            bio: artist.bio,
        });

        setPrevData(data);
    }
    // eslint-disable-next-line
    , [artist]);

    useEffect(() => {
        ResetMessages(data, prevData, setPrevData, errors, setErrors, setErrorMessage);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
    );

    const handleSubmit = async (e) => {
        e.preventDefault();

        setWorking(true);
        ResetMessages(data, prevData, setPrevData, errors, setErrors, setErrorMessage, setSuccessMessage, true);

        try {
            const res = await Api.put(`/artists/${artist.id}`, data);
            setSuccessMessage(res.data.message);
            getArtist();
        } catch (err) {
            SetFormErrors(err, errors, setErrors, setErrorMessage);
        } finally {
            setWorking(false);
        }
    }

    return (
        <>
            {loading || Object.keys(data).length === 0 ?
                <BlockOverlay />
            :
                <Form className="mt-5" onSubmit={handleSubmit}>
                    <Row>
                        <Col xs={12} md={6}>
                            <Form.Group className="mb-2" controlId="type">
                                <Form.Label>{t('type')}</Form.Label>
                                <Select
                                    value={artistTypeOptions.find((option) => option.value === data.type)}
                                    options={artistTypeOptions}
                                    styles={selectStyles}
                                    placeholder={t('selectDots')}
                                    className={`react-select ${errors.type ? 'is-invalid' : ''}`}
                                    onChange={(option) => setData({ ...data, type: option.value })}
                                    isDisabled={working}
                                />
                                {errors.type && <Form.Control.Feedback type="invalid" style={{display: 'block'}}>{errors.type}</Form.Control.Feedback>}
                            </Form.Group>
                        </Col>

                        <Col xs={12} md={6}>
                            <Form.Group className="mb-2" controlId="name">
                                <Form.Label>{t('name')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t('name')}
                                    value={data.name || ''}
                                    onChange={(e) => setData({ ...data, name: e.target.value })}
                                    isInvalid={!!errors.name}
                                    {...working ? { disabled: true } : {}}
                                />
                                {errors.name && <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>}
                            </Form.Group>
                        </Col>

                        <Col xs={12} md={6}>
                            <Form.Group className="mb-2" controlId="instagram_url">
                                <Form.Label>{t('instagramUrl')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t('instagramUrl')}
                                    value={data.instagram_url || ''}
                                    onChange={(e) => setData({ ...data, instagram_url: e.target.value })}
                                    isInvalid={!!errors.instagram_url}
                                    {...working ? { disabled: true } : {}}
                                />
                                {errors.instagram_url && <Form.Control.Feedback type="invalid">{errors.instagram_url}</Form.Control.Feedback>}
                            </Form.Group>
                        </Col>

                        <Col xs={12} md={6}>
                            <Form.Group className="mb-2" controlId="soundcloud_url">
                                <Form.Label>{t('soundcloudUrl')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={t('soundcloudUrl')}
                                    value={data.soundcloud_url || ''}
                                    onChange={(e) => setData({ ...data, soundcloud_url: e.target.value })}
                                    isInvalid={!!errors.soundcloud_url}
                                    {...working ? { disabled: true } : {}}
                                />
                                {errors.soundcloud_url && <Form.Control.Feedback type="invalid">{errors.soundcloud_url}</Form.Control.Feedback>}
                            </Form.Group>
                        </Col>

                        <Col xs={12}>
                            <Form.Group className="mb-2" controlId="bio">
                                <Form.Label>{t('bio')}</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    placeholder={t('bio')}
                                    value={data.bio || ''}
                                    onChange={(e) => setData({ ...data, bio: e.target.value })}
                                    isInvalid={!!errors.bio}
                                    {...working ? { disabled: true } : {}}
                                />
                                {errors.bio && <Form.Control.Feedback type="invalid">{errors.bio}</Form.Control.Feedback>}
                            </Form.Group>
                        </Col>

                        <Col xs={12}>
                            <Button type="submit" variant="primary" className="mt-3" {...working ? {disabled: true} : {} }>
                                {working && <FontAwesomeIcon icon={faSpinner} spin className="me-2" />}
                                {t('update')}
                            </Button>

                            {errorMessage && <FormMessage type="danger" message={errorMessage} />}
                            {successMessage && <FormMessage type="success" message={successMessage} />}
                        </Col>
                    </Row>
                </Form>
            }
        </>
    );
}

export default ArtistEditInfoForms;