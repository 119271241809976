import { useState, useEffect } from 'react';
import { ListProvider } from '../../utils/hooks/ListProvider';
import { useParams } from 'react-router-dom';
import { Api } from '../../utils/services/Api';
import ListLayout from '../../components/global/ListLayout';
import ListOverview from '../../components/list/ListOverview';
import ListUsersFilterForm from '../../components/list/ListUsersFilterForm';
import ListUsersList from '../../components/list/ListUsersList';

function ListUsersPage() {

    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [data, setData] = useState({});

    const setFilterData = (data) => {
        setData(data);
    }

    const getUsers = async () => {

        setLoading(true);
        try {
            const res = await Api.get('/lists/' + id + '/users', {
                params: {
                    ...data
                }
            });
            setLoading(false);
            setUsers(res.data.data);
        } catch (err) {
            setUsers([]);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getUsers();
    },
    // eslint-disable-next-line
    [data]);

    return (
        <ListLayout>
            <div className="block shadow position-relative">
                <ListProvider>
                    <ListOverview />
                    <ListUsersFilterForm callback={setFilterData} />
                    <ListUsersList loading={loading} users={users} />
                </ListProvider>
            </div>
        </ListLayout>
    );
}

export default ListUsersPage;