import { faAngleLeft, faAngleRight, faAnglesLeft, faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Button } from 'react-bootstrap';

function Pagination({ page, lastPage, setPage }) {
    return (
        <Container fluid className="mt-4">
            <ul className="m-0 p-0 list-inline list-unstyled d-flex align-items-center justify-content-end">
                <li className="list-inline-item">
                    <Button
                        variant="primary"
                        className="pagination-page"
                        {...page === 1 ? { disabled: true } : {}}
                        onClick={() => setPage(1)}
                    >
                        <FontAwesomeIcon icon={faAnglesLeft} />
                    </Button>
                </li>

                <li className="list-inline-item">
                    <Button
                        variant="primary"
                        className="pagination-page"
                        {...page === 1 ? { disabled: true } : {}}
                        onClick={() => setPage(page - 1)}
                    >
                        <FontAwesomeIcon icon={faAngleLeft} />
                    </Button>
                </li>

                <li className="list-inline-item">
                    <Button
                        variant="primary"
                        className="pagination-page"
                        disabled={true}
                    >
                        {page}
                    </Button>
                </li>

                <li className="list-inline-item">
                    <Button
                        variant="primary"
                        className="pagination-page"
                        {...page === lastPage ? { disabled: true } : {}}
                        onClick={() => setPage(page + 1)}
                    >
                        <FontAwesomeIcon icon={faAngleRight} />
                    </Button>
                </li>

                <li className="list-inline-item">
                    <Button
                        variant="primary"
                        className="pagination-page"
                        {...page === lastPage ? { disabled: true } : {}}
                        onClick={() => setPage(lastPage)}
                    >
                        <FontAwesomeIcon icon={faAnglesRight} />
                    </Button>
                </li>
            </ul>
        </Container>
    );
}

export default Pagination;