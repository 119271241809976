import { useState, useEffect, createContext, useContext, useMemo } from 'react';
import { Api } from '../services/Api';

const ParamsContext = createContext();

export const ParamsProvider = ({ children }) => {

    const [params, setParams] = useState({});

    const getParams = async () => {
        try {
            const res = await Api.get('/params');
            setParams(res.data.data);
        } catch (err) {
            // Silence is golden.
        }
    }

    useEffect(() => {
        getParams();
    }
    // eslint-disable-next-line
    , []);

    const value = useMemo(
        () => ({
            params
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [params]
    );

    return (
        <ParamsContext.Provider value={value}>
            {params && children}
        </ParamsContext.Provider>
    );
}

export const useParams = () => {
    return useContext(ParamsContext);
}