import { useState } from 'react';
import { Api } from '../../utils/services/Api';
import { useEvent } from '../../utils/hooks/EventProvider';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

function EventListDelete({ list }) {

    const { t } = useTranslation();
    const { getEvent } = useEvent();
    const [working, setWorking] = useState(false);

    const handleDelete = async () => {

        setWorking(true);
        try {
            await Api.delete(`/event-lists/${list.id}`);
            getEvent();
        } catch (error) {
            // Silence is golden.
        } finally {
            setWorking(false);
        }
    }
    
    return (
        <Button
            variant="danger"
            className="btn-sm rounded me-2"
            onClick={handleDelete}
            {...working && { disabled: true }}
        >
            {working && <FontAwesomeIcon icon={faSpinner} spin className="me-2" />}
            {t('delete')}
        </Button>
    )
}

export default EventListDelete