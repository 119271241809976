import { useState } from 'react';
import { useArtist } from '../../utils/hooks/ArtistProvider';
import { useTranslation } from 'react-i18next';
import { Button, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Api } from '../../utils/services/Api';

function ArtistGalleryImage({ image, lightbox }) {

    const { t } = useTranslation();
    const { artist, getArtist } = useArtist();
    const [working, setWorking] = useState(false);

    const handleDelete = async () => {
        setWorking(true);
        
        try {
            await Api.delete(`/artists/${artist.id}/gallery/${image.id}`);
            getArtist();
        } catch (err) {
            // Silence is golden.
        } finally {
            setWorking(false);
        }
    }


    return (
        <div className="position-relative">
            <Image
                src={image.url}
                fluid
                className="gallery-image rounded"
                style={{
                    width: '100%',
                    height: '175px',
                    objectFit: 'cover',
                    cursor: 'pointer'
                }}
                onClick={() => lightbox(true)}
            />

            <Button
                variant="danger"
                className="btn-sm rounded-0 position-absolute bottom-0 start-0"
                onClick={handleDelete}
                {...working ? {disabled: true} : {}}
            >
                {working && <FontAwesomeIcon icon={faSpinner} spin className="me-2" />}
                {t('delete')}
            </Button>
        </div>
    );
}

export default ArtistGalleryImage;