import { useTranslation } from 'react-i18next';
import { useEvent } from '../../utils/hooks/EventProvider';
import EventListSingle from './EventListSingle';
import BlockOverlay from '../global/BlockOverlay';
import EventListCreate from './EventListCreate';
import EventListsGenerate from './EventListsGenerate';
import EventListSendCheckInvitations from './EventListSendCheckInvitations';

function EventLists() {

    const { t } = useTranslation();
    const { loading, event } = useEvent();

    return (
        <>
            {loading ?
                <BlockOverlay />
            :
                <div className="profile-overview">
                    <h3>{t('lists')} - {event.name}</h3>

                    <div className="mb-5 d-flex align-items-center">
                        <EventListCreate />
                        {event.lists.length === 0 ?
                            <EventListsGenerate />
                        :
                            <EventListSendCheckInvitations />
                        }
                    </div>

                    {event.lists.map((list, index) => (
                        <EventListSingle key={index} list={list} />
                    ))}
                </div>
            }
        </>
    );
}

export default EventLists;